<div class="iv-references">
  <div (click)="referencesExpanded = !referencesExpanded" class="iv-references__title">
    <div>References</div>
    <mat-icon [ngClass]="{ 'iv-references__button-toggle-expanded': referencesExpanded }">keyboard_arrow_right</mat-icon>
  </div>
  <div *ngIf="referencesExpanded" class="iv-chatbot-references">
    <div *ngFor="let reference of message.quotedReferenceList">
      <div>
        <span>{{ reference.reference_id }}. </span>
        <a class="iv-references__references-quoted" (click)="logReference(reference.url, reference.title, reference.page_num, reference.published_date)" [href]="reference.url" target="_blank">{{ reference.title }}</a>
        <span *ngIf="reference.page_num"> [Page {{ reference.page_num }}]</span>
        <span *ngIf="reference.published_date"> ({{ reference.published_date }})</span>
      </div>
    </div>
    <ng-container *ngIf="message?.noQuotedReferenceList?.length > 0">
      <div (click)="additionalReferenceExpanded = !additionalReferenceExpanded" class="iv-references__title">
        <div>Additional References</div>
        <mat-icon [ngClass]="{ 'iv-references__button-toggle-expanded': additionalReferenceExpanded }">keyboard_arrow_right</mat-icon>
      </div>
      <ng-container *ngIf="additionalReferenceExpanded">
        <div *ngFor="let reference of message.noQuotedReferenceList">
          <div>
            <span>{{ reference.reference_id }}. </span>
            <a (click)="logReference(reference.url, reference.title, reference.page_num, reference.published_date)" [href]="reference.url" target="_blank">{{ reference.title }}</a>
            <span *ngIf="reference.page_num"> [Page {{ reference.page_num }}]</span>
            <span *ngIf="reference.published_date"> ({{ reference.published_date }})</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {WebsocketService} from './websocket.service';
import {ChatService, Message, Reference} from './chat.service';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormControl, FormsModule} from '@angular/forms';
import {v4 as uuidv4} from 'uuid';
import {MatIconModule} from '@angular/material/icon';
import {MessageComponent} from './message/message.component';
import {ReferencesComponent} from './references/references.component';
import {MessageClipboardDirective} from './message-clipboard/message-clipboard.directive';
import {RatingPanelComponent} from './rating-panel/rating-panel.component';
import {PromptActionsDirective} from './prompt-actions/prompt-actions.directive';
import {ClipboardIconComponent} from './message-clipboard/clipboard-icon.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ThinkingLoaderComponent} from './thinking-loader/thinking-loader.component';
import {MatDividerModule} from '@angular/material/divider';
import {HallucinationComponent} from './hallucination/hallucination.component';
import {CONVERSATION_STYLE} from './hallucination/conversation-style';
import {PromptInputComponent} from './prompt-input/prompt-input.component';
import {MatButtonModule} from '@angular/material/button';
import {TokenTrackerComponent} from './token-tracker/token-tracker.component';
import {SearchService} from '../../services/search/search.service';
import {IntelligenceTypeComponent} from './token-tracker/intelligence-type/intelligence-type.component';
import {LogService} from 'src/app/services/log/log.service';
import {environment as ENV} from '../../../environments/environment';
import {Constants} from "../../constants/constants";
import {ChatbotDisclosureComponent} from "../../dialogs/chatbot-disclosure/chatbot-disclosure.component";

export const AUTHOR = {
  CHATBOT: 'chatbot',
  COMPLETED_MESSAGE: 'chatbot-end',
};

@Component({
  selector: 'iv-chatbot',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    NgOptimizedImage,
    MessageComponent,
    ReferencesComponent,
    MessageClipboardDirective,
    RatingPanelComponent,
    PromptActionsDirective,
    ClipboardIconComponent,
    ThinkingLoaderComponent,
    HallucinationComponent,
    PromptInputComponent,
    TokenTrackerComponent,
    IntelligenceTypeComponent,
  ],
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  providers: [WebsocketService, ChatService],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotComponent implements OnDestroy, OnInit {
  messageList: Message[] = [];
  currentAnswer: {
    response: string;
    references: Reference[];
    quotedReferenceList: Reference[];
    noQuotedReferenceList: Reference[];
  } = {
    response: '',
    references: [],
    quotedReferenceList: [],
    noQuotedReferenceList: [],
  };
  typing = false;
  uniqueId = uuidv4();
  AUTHOR = AUTHOR;
  conversationStyle = new FormControl<CONVERSATION_STYLE>(
    CONVERSATION_STYLE.PRECISE
  );
  promptControl = new FormControl<string>('');
  usedToken = 0;
  clickedInterest = false;
  @Input() store: any;
  @Input() allowedFeature = false;

  disclosureAgreed: boolean = false;

  // tracks if we have received an error in the format of:
  // '####ERROR: ####, error_message: {"statusCode": 429, "body": "Too Many Requests"}'
  errorState: boolean = false;
  errorReturnPattern: RegExp = new RegExp('^####ERROR:', 'i');
  errorObjectPattern: RegExp = new RegExp('error_message:\\s*({.*})');
  errorCaptured: string = '';
  errorStatusCode: number = 0;

  // Simulate an error response - for TESTING / DEBUG purposes only!
  // if you proceed a question with '###429 Question...'
  // it will replace the chatbot response with an error string looking like:
  //    ####ERROR: ####, error_message: {"statusCode": 429, "body": "Too Many Requests"}
  enableSimulateError: boolean = true;
  errorSendPattern: RegExp = new RegExp('^###(\\d{3})(.*?)\\s*$');
  firstResponse: boolean = true;

  constructor(
    private chatService: ChatService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private searchService: SearchService,
    private logService: LogService,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(document.body, 'intel-chatbot')
    const chatHistory = window.sessionStorage.getItem('chatHistory');
    if (chatHistory) {
      const storageData = JSON.parse(chatHistory);
      this.messageList = storageData.messages;
      this.uniqueId = storageData.chatId;
    }
    chatService.messages.subscribe(async (message: Message) => {
      let messageContents = message.user_input;
      if (this.typing) {
        messageContents = messageContents.slice(1);
      }
      this.typing = false;

      if (message.references && !this.errorState) {
        const referencesSorted = message.references.sort(
          (a: any, b: any) => a.reference_id - b.reference_id
        );
        const [quotedReferenceList, noQuotedReferenceList] =
          referencesSorted.reduce(
            ([quotedList, noQuotedList], reference) => {
              if (reference.referenced) {
                quotedList = [...quotedList, reference];
              } else {
                noQuotedList = [...noQuotedList, reference];
              }
              return [quotedList, noQuotedList];
            },
            [[], []] as [Reference[], Reference[]]
          );
        this.currentAnswer = {
          response: this.currentAnswer.response,
          references: referencesSorted,
          quotedReferenceList,
          noQuotedReferenceList,
        };
      } else if (message.author === AUTHOR.CHATBOT) {

        // simulate error
        if(this.enableSimulateError && (this.errorStatusCode > 0)) {
          if(this.firstResponse) {
            switch(this.errorStatusCode) {
              case 429:
                this.errorCaptured = `####ERROR: ####, error_message: {"statusCode": ${this.errorStatusCode}, "body": "Too Many Requests"}`;
                break;
              default:
                this.errorCaptured = `####ERROR: ####, error_message: {"statusCode": ${this.errorStatusCode}, "body": "Unknown Error"}`;
                break;
            }
            messageContents = this.errorCaptured;
            this.firstResponse = false;
          } else {
            messageContents = '';
          }

        }

        // process error
        if(!this.errorState && messageContents) {
          if(this.errorReturnPattern.test(messageContents)) {
            this.errorState = true;
          }
        }

        this.currentAnswer = {
          response: this.currentAnswer.response.concat(this.errorState ? '' : messageContents),
          references: [],
          quotedReferenceList: [],
          noQuotedReferenceList: [],
        };
      } else if (message.author === AUTHOR.COMPLETED_MESSAGE) {

        // manage error state
        if(this.errorState) {
          const match = this.errorCaptured.match(this.errorObjectPattern);
          if(match && match[1]) {
            const errorMessageJSONString: string = match[1];
            const errorMessageObject: any = JSON.parse(errorMessageJSONString);
            this.errorStatusCode = errorMessageObject.statusCode;
            switch(errorMessageObject.statusCode) {
              case 429:
                this.currentAnswer.response = 'Whoops! Enverus Instant Analyst is Hitting Maximum Capacity!\n\n' +
                  'Looks like we\'ve hit a little bottleneck.  Our sincere apologies for the hold-up.\n\n' +
                  'We\'re just running the Enverus Instant Analyst through it\'s paces, so this kind of traffic jam is ' +
                  'temporary.  Your input is incredibly valuable - it\'s helping us smooth out the edges.\n\n' +
                  'Why not take a short break?  We\'ll be up and running smoothly shortly, ready to serve up those ' +
                  'insights for you.\n';
                break;
              default:
                this.currentAnswer.response = 'An error occurred.  Please try again later.';
                break;
            }
          }
        }
        const {
          response: user_input,
          references,
          quotedReferenceList,
          noQuotedReferenceList,
        } = this.currentAnswer;
        const finalMessage: any = {
          user_input,
          author: AUTHOR.CHATBOT,
          references,
          quotedReferenceList,
          noQuotedReferenceList,
          ratingExpanded: false,
          prompt_id: message.prompt_id,
        };
        if(this.errorState) {
          finalMessage.errorStatusCode = this.errorStatusCode;
        }
        this.messageList = [...this.messageList, finalMessage];

        // error state cleanup
        this.errorState = false;
        this.errorCaptured = '';
        this.errorStatusCode = 0;
        this.firstResponse = true;

        this.changeDetectorRef.detectChanges();
        this.usedToken = 0;
        this.currentAnswer = {
          response: '',
          references: [],
          quotedReferenceList: [],
          noQuotedReferenceList: [],
        };
      }
    });

  }

  ngOnInit() {
    const _self = this;

    // determine if they have already agreed to the current disclosure
    if(this.allowedFeature) {
      const da: string | null = sessionStorage.getItem(Constants.chatbotStorageKey);  // localStorage.
      if (da && da.length) {
        this.decryptData(ENV.CHATBOT.disclosureAes256Key, da).then(function (disclosureDate: string) {
          if (disclosureDate === Constants.chatbotDisclosureDate) {
            _self.disclosureAgreed = true;
            _self.changeDetectorRef.detectChanges();
          } else {
            _self.showDisclosure();
          }
        });
      } else {
        _self.showDisclosure();
      }
    }

  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'intel-chatbot')
    const data = { chatId: this.uniqueId, messages: this.messageList };

    const jsonData = JSON.stringify(data);
    // console.log(jsonData);

    window.sessionStorage.setItem('chatHistory', jsonData);
  }

  public setUsedTokens(tokenCount: number): void {
    this.usedToken = tokenCount;
  }

  sendUserMessage(user_input: string) {
    if (!user_input) {
      return;
    }

    if(this.enableSimulateError) {
      // ie: user question is [###429 Name the top operators in the USA in 2023\n]
      const match = user_input.match(this.errorSendPattern);
      if(match) {
        this.errorStatusCode = parseInt(match[1], 10);
        console.log(`*** SIMULATING ERROR ${this.errorStatusCode} ***`);
        user_input = match[2].trim();
      }
    }

    const message = {
      action: 'sendmessage',
      product: 'intelligence',
      user_input,
      authorizer: window.sessionStorage.getItem('access_token') ?? '',
      conversation_id: this.uniqueId,
      temperature: this.conversationStyle.value,
    };
    this.messageList = [...this.messageList, message];
    this.typing = true;
    this.promptControl.setValue('');
    this.chatService.messages.next(message);
    this.changeDetectorRef.detectChanges();
  }

  restartConversation() {
    window.sessionStorage.removeItem('chatHistory');
    this.messageList = [];
    this.currentAnswer = {
      response: '',
      references: [],
      quotedReferenceList: [],
      noQuotedReferenceList: [],
    };
    this.typing = false;
    this.uniqueId = uuidv4();
    this.AUTHOR = AUTHOR;
    this.conversationStyle.setValue(CONVERSATION_STYLE.PRECISE);
    this.promptControl.setValue('');
  }

  interested(value: boolean) {
    this.logService.logPendo('ChatBot Interest', {
      key: value,
    });
    this.clickedInterest = true;
  }

// Helper function to convert hex string to ArrayBuffer
  hexStringToArrayBuffer(hexString: string): ArrayBuffer {
    const buffer: Uint8Array = new Uint8Array(hexString.length / 2);
    for (let i: number = 0; i < hexString.length; i += 2) {
      buffer[i / 2] = parseInt(hexString.substring(i, i + 2), 16);
    }
    return buffer.buffer;
  }

  async encryptData(aes256key: string, data: string): Promise<string> {
    const keyBuffer: CryptoKey = await window.crypto.subtle.importKey(
      'raw',
      this.hexStringToArrayBuffer(aes256key),
      { name: 'AES-GCM' },
      false,
      ['encrypt']
    );

    const iv: Uint8Array = window.crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
    const encodedData: Uint8Array = new TextEncoder().encode(data);

    const encryptedBuffer: ArrayBuffer = await window.crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      keyBuffer,
      encodedData
    );

    // Concatenate IV and encrypted data, and encode as base64
    const combinedData: Uint8Array = new Uint8Array([...iv, ...new Uint8Array(encryptedBuffer)]);
    return btoa(String.fromCharCode.apply(null, Array.from(combinedData)));
  }

  async decryptData(aes256key: string, encryptedString: string): Promise<string> {
    const combinedData: Uint8Array = new Uint8Array(atob(encryptedString).split('').map(char => char.charCodeAt(0)));
    const iv: Uint8Array = combinedData.slice(0, 12);
    const encryptedBuffer: Uint8Array = combinedData.slice(12);

    const keyBuffer: CryptoKey = await window.crypto.subtle.importKey(
      'raw',
      this.hexStringToArrayBuffer(aes256key),
      { name: 'AES-GCM' },
      false,
      ['decrypt']
    );

    const decryptedBuffer: ArrayBuffer = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: iv },
      keyBuffer,
      encryptedBuffer
    );

    return new TextDecoder().decode(decryptedBuffer);
  }

  public showDisclosure() {
    const dialogRef = this.dialog.open(ChatbotDisclosureComponent, {
      panelClass: 'iv-chatbot-disclosure-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: ''
    });
    dialogRef.afterClosed().subscribe( async result => {
      if (result) {

        this.logService.logPendo('ChatBot Disclosure Agreed', {
          key: true,
        });
        const encryptedData: string = await this.encryptData(ENV.CHATBOT.disclosureAes256Key, Constants.chatbotDisclosureDate);
        sessionStorage.setItem(Constants.chatbotStorageKey, encryptedData);  // localStorage.

        this.disclosureAgreed = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from '../../../environments/environment';

import {IvauthService} from './ivauth.service';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: IvauthService, public router: Router, private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.auth.isAuthenticated()) {
      if (environment.preview) {
        const profile = this.userService.getUserProfile();
        if (this.isEmailMatch(profile.email)) {
          return true;
        } else {
          this.router.navigate(['preview']);
          return false;
        }
      }
      // this.themeService.setLoggedIn();
      return true;
    }
    this.auth.setRedirectUrl(url); // set url in authService here
    this.router.navigate(['']); // then ask user to login
    return false;
  }

  isEmailMatch(email: string) {
    // Check if the email matches any of the domains
    if (environment.PREVIEW && environment.PREVIEW.DOMAINS) {
      for (const domain of environment.PREVIEW.DOMAINS) {
        if (email.endsWith(domain)) {
          return true;
        }
      }
    }

    if (environment.PREVIEW && environment.PREVIEW.USERS) {
      // Check if the email matches any of the users
      for (const user of environment.PREVIEW.USERS) {
        if (email === user) {
          return true;
        }
      }
    }
    return false;
  }
}

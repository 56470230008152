// ----------------------------------------------------------------------------
//  This service is for data sharing / communication between other services
//    & components.
//
//  *** It MUST not have any other project dependencies (other than constants.ts).
//      Observables and getters/setters ONLY. ***
// ----------------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {Observable, BehaviorSubject, ReplaySubject} from 'rxjs';
import { take } from "rxjs/operators";

import { Constants } from "../../constants/constants";
const reportsStores: string[] = Constants.ReportsStores;
const livefeedStores: string[] = Constants.LivefeedStores;

import { ActiveFilter, createEmptyActiveFilter } from "../../interface/activefilter";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // --------------------------------------------------------------------------
  // REPORTS PANE
  // --------------------------------------------------------------------------

  // tracks the active tab / collection for Reports pane
  private reportsTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>(reportsStores[0]);
  public reportsTab$: Observable<string> = this.reportsTabSubject.asObservable();

  // tracks the active filter for Reports pane
  private reportsActiveFilterSubject: BehaviorSubject<ActiveFilter> = new BehaviorSubject<ActiveFilter>(
    createEmptyActiveFilter()
  );
  public reportsActiveFilter$: Observable<ActiveFilter> = this.reportsActiveFilterSubject.asObservable();

  // tracks the default filter id for Reports pane
  private reportsDefaultFilterIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public reportsDefaultFilterId$: Observable<number> = this.reportsDefaultFilterIdSubject.asObservable();

  // tracks whether we are loading a saved filter
  private reportsLoadingSavedFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public reportsLoadingSavedFilter$: Observable<boolean> = this.reportsLoadingSavedFilterSubject.asObservable();

  // serves dual purpose:
  // on startup: tracks urlkey of saved url that was passed on uri (ie: ?rf=1234567890ABCD)
  // on facets change: tracks the last generated urlkey ([Get Filters Url] pressed)
  private reportsSavedUrlFilterSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  public reportsSavedUrlFilter$: Observable<string> = this.reportsSavedUrlFilterSubject.asObservable();

  private reportsLoadUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public reportsLoadUrl$: Observable<string> = this.reportsLoadUrlSubject.asObservable();

  // pretty text for facets (plain text w linefeeds for use with <title>)
  private reportsFacetsPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public reportsFacetsPrettyText$: Observable<string> = this.reportsFacetsPrettyTextSubject.asObservable();

  // pretty text for angular material chip (one liner, no keywords, etc...)
  private reportsChipPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public reportsChipPrettyText$: Observable<string> = this.reportsChipPrettyTextSubject.asObservable();
  // private reportsChipPrettyTextSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  // public reportsChipPrettyText$: Observable<string> = this.reportsChipPrettyTextSubject.asObservable();


  // --------------------------------------------------------------------------
  // LIVEFEED PANE
  // --------------------------------------------------------------------------

  // tracks the active tab / collection for LiveFeed pane
  private livefeedTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>(livefeedStores[0]);
  public livefeedTab$: Observable<string> = this.livefeedTabSubject.asObservable();

  // tracks the active filter for LiveFeed pane
  private livefeedActiveFilterSubject: BehaviorSubject<ActiveFilter> = new BehaviorSubject<ActiveFilter>(
    createEmptyActiveFilter()
  );
  public livefeedActiveFilter$: Observable<ActiveFilter> = this.livefeedActiveFilterSubject.asObservable();

  // tracks the default filter id for LiveFeed pane
  private livefeedDefaultFilterIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public livefeedDefaultFilterId$: Observable<number> = this.livefeedDefaultFilterIdSubject.asObservable();

  // tracks whether we are loading a saved filter
  private livefeedLoadingSavedFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public livefeedLoadingSavedFilter$: Observable<boolean> = this.livefeedLoadingSavedFilterSubject.asObservable();

  // serves dual purpose:
  // on startup: tracks urlkey of saved url that was passed on uri (ie: ?lff=1234567890ABCD)
  // on facets change: tracks the last generated urlkey ([Get Filters Url] pressed)
  private livefeedSavedUrlFilterSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  public livefeedSavedUrlFilter$: Observable<string> = this.livefeedSavedUrlFilterSubject.asObservable();

  private livefeedLoadUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public livefeedLoadUrl$: Observable<string> = this.livefeedLoadUrlSubject.asObservable();

  // pretty text for facets (plain text w linefeeds for use with <title>)
  private livefeedFacetsPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public livefeedFacetsPrettyText$: Observable<string> = this.livefeedFacetsPrettyTextSubject.asObservable();

  // pretty text for angular material chip (one liner, no keywords, etc...)
  private livefeedChipPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public livefeedChipPrettyText$: Observable<string> = this.livefeedChipPrettyTextSubject.asObservable();


  constructor(private route: ActivatedRoute,
  ) {

    if(typeof(this.route.snapshot.queryParams[Constants.reportsSavedFiltersUrl]) !== 'undefined') {
      this.updateReportsSavedUrlFilter(this.route.snapshot.queryParams[Constants.reportsSavedFiltersUrl]);
    }

    if(typeof(this.route.snapshot.queryParams[Constants.livefeedSavedFiltersUrl]) !== 'undefined') {
      this.updateLivefeedSavedUrlFilter(this.route.snapshot.queryParams[Constants.livefeedSavedFiltersUrl]);
    }

    this.updateReportsTab(Constants.ReportsStores[0]);

  }


  // --------------------------------------------------------------------------
  // REPORTS PANE
  // --------------------------------------------------------------------------

  // set the active tab / collection for Reports pane
  updateReportsTab(tab: string) {
    this.reportsTabSubject.next(tab);
  }

  updateReportsActiveFilter(af: ActiveFilter) {
    this.reportsActiveFilterSubject.next(af);
  }

  updateReportsDefaultFilterId(id: number) {
    this.reportsDefaultFilterIdSubject.next(id);
  }

  updateReportsLoadingSavedFilter(state: boolean) {
    this.reportsLoadingSavedFilterSubject.next(state);
  }

  updateReportsSavedUrlFilter(rf: string) {
    this.reportsSavedUrlFilterSubject.next(rf);
  }

  updateReportsLoadUrl(urlkey: string) {
    this.reportsLoadUrlSubject.next(urlkey);
  }

  updateReportsFacetsPrettyText(text: string) {
    this.reportsFacetsPrettyTextSubject.next(text);
  }

  updateReportsChipPrettyText(text: string) {
    this.reportsChipPrettyTextSubject.next(text);
  }

  dumpReportsActiveFilter() {
    this.reportsActiveFilter$.pipe(
      take(1),
    ).subscribe((activeFilter: ActiveFilter) => {
    });
  }


  // --------------------------------------------------------------------------
  // LIVEFEED PANE
  // --------------------------------------------------------------------------

  // set the active tab / collection for Livefeed pane
  updateLivefeedTab(tab: string) {
    this.livefeedTabSubject.next(tab);
  }

  updateLivefeedActiveFilter(af: ActiveFilter) {
    this.livefeedActiveFilterSubject.next(af);
  }

  updateLivefeedDefaultFilterId(id: number) {
    this.livefeedDefaultFilterIdSubject.next(id);
  }

  updateLivefeedLoadingSavedFilter(state: boolean) {
    this.livefeedLoadingSavedFilterSubject.next(state);
  }

  updateLivefeedSavedUrlFilter(lff: string) {
    this.livefeedSavedUrlFilterSubject.next(lff);
  }

  updateLivefeedLoadUrl(urlkey: string) {
    this.livefeedLoadUrlSubject.next(urlkey);
  }

  updateLivefeedFacetsPrettyText(text: string) {
    this.livefeedFacetsPrettyTextSubject.next(text);
  }

  updateLivefeedChipPrettyText(text: string) {
    this.livefeedChipPrettyTextSubject.next(text);
  }


  // --------------------------------------------------------------------------
  // COMMON
  // --------------------------------------------------------------------------

  clearActiveFilter(storeKey: string, storeFacets: any = null): void {

    if( reportsStores.indexOf(storeKey) >= 0 ) {

      this.reportsActiveFilter$.pipe(
        take(1),
      ).subscribe((activeFilter: ActiveFilter) => {

        let initActiveFilter: ActiveFilter = createEmptyActiveFilter();
        if(storeFacets) {
          initActiveFilter.facetsDiff = storeFacets;
        }
        this.updateReportsActiveFilter(initActiveFilter);

      });

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {

          let initActiveFilter: ActiveFilter = createEmptyActiveFilter();
          if(storeFacets) {
            initActiveFilter.facetsDiff = storeFacets;
          }
          this.updateLivefeedActiveFilter(initActiveFilter);

        });

      }

    }

  }

  updateActiveFilter(storeKey: string, activeFilter: ActiveFilter): void {
    if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.updateReportsActiveFilter(activeFilter);

    } else {
      if (livefeedStores.indexOf(storeKey) >= 0) {
          this.updateLivefeedActiveFilter(activeFilter);
      }

    }
  }

  async updateActiveFilterParameter(storeKey: string, parameterKey: string, parameterVal: any): Promise<void> {
    return new Promise((resolve, reject) => {

      if (reportsStores.indexOf(storeKey) >= 0) {

        this.reportsActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          if (activeFilter.hasOwnProperty(parameterKey)) {
            if(activeFilter[parameterKey] !== parameterVal) {
              activeFilter[parameterKey] = parameterVal;
              this.updateReportsActiveFilter(activeFilter);
            }
          }
          resolve();
        });

      } else {
        if (livefeedStores.indexOf(storeKey) >= 0) {

          this.livefeedActiveFilter$.pipe(
            take(1),
          ).subscribe((activeFilter: ActiveFilter) => {
            if (activeFilter.hasOwnProperty(parameterKey)) {
              if(activeFilter[parameterKey] !== parameterVal) {
                activeFilter[parameterKey] = parameterVal;
                this.updateLivefeedActiveFilter(activeFilter);
              }
            }
            resolve();
          });

        }

      }

    });
  }

  async updateActiveFilterFacetsDiffParameter(storeKey: string, facetsDiffKey: string, parameterKey: string, parameterVal: any): Promise<void> {
    return new Promise((resolve, reject) => {

      if (reportsStores.indexOf(storeKey) >= 0) {

        this.reportsActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          const rec = activeFilter.facetsDiff['facets'].find(
            (facet: any) => facet.key === facetsDiffKey
          );
          if (rec) {
            if (rec.hasOwnProperty(parameterKey)) {
              rec[parameterKey] = parameterVal;
              this.updateReportsActiveFilter(activeFilter);
            }
          }
          resolve();
        });

      } else {
        if (livefeedStores.indexOf(storeKey) >= 0) {

          this.livefeedActiveFilter$.pipe(
            take(1),
          ).subscribe((activeFilter: ActiveFilter) => {
            const rec = activeFilter.facetsDiff['facets'].find(
              (facet: any) => facet.key === facetsDiffKey
            );
            if (rec) {
              if (rec.hasOwnProperty(parameterKey)) {
                rec[parameterKey] = parameterVal;
                this.updateReportsActiveFilter(activeFilter);
              }
            }
            resolve();
          });

        }

      }

    });
  }

  isActiveFilterInited(storeKey: string): boolean {
    let isinit: boolean = false;

    if( reportsStores.indexOf(storeKey) >= 0 ) {

      this.reportsActiveFilter$.pipe(
        take(1),
      ).subscribe((activeFilter: ActiveFilter) => {
        isinit = Object.keys(activeFilter.facetsDiff).length > 0;
      });

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          isinit = Object.keys(activeFilter.facetsDiff).length > 0;
        });

      }

    }

    return(isinit);
  }

  getActiveFilterParameter(storeKey: string, parameter: string): any {
    let result: any = null;

    if( reportsStores.indexOf(storeKey) >= 0 ) {

      this.reportsActiveFilter$.pipe(
        take(1),
      ).subscribe((activeFilter: ActiveFilter) => {
        if(activeFilter.hasOwnProperty(parameter)) {
          result = activeFilter[parameter];
        }
      });

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          if(activeFilter.hasOwnProperty(parameter)) {
            result = activeFilter[parameter];
          }
        });

      }

    }

    return(result);
  }

  testActiveFilterModified(storeKey: string, storeFacets: any): void {

    if( (reportsStores.indexOf(storeKey) >= 0) && (storeKey !== 'ReportsNew') ) {

        this.reportsActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {

          // facet counts can change and must not be considered in comparison
          let storeFacetsCleaned = JSON.parse( JSON.stringify( storeFacets ) );
          storeFacetsCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });
          let facetsDiffCleaned = JSON.parse( JSON.stringify( activeFilter.facetsDiff ) );
          facetsDiffCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });

          let modified: boolean =
            (activeFilter.title !== activeFilter.titleOriginal) ||
            (activeFilter.q !== activeFilter.qOriginal) ||
            (JSON.stringify(storeFacetsCleaned) !== JSON.stringify(facetsDiffCleaned));

          this.updateActiveFilterParameter(storeKey, 'filterModified', modified);
        });

    } else {

      if ( (livefeedStores.indexOf(storeKey) >= 0) && (storeKey !== 'LiveFeedNew') ) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {

          // facet counts can change and must not be considered in comparison
          let storeFacetsCleaned = JSON.parse( JSON.stringify( storeFacets ) );
          storeFacetsCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });
          let facetsDiffCleaned = JSON.parse( JSON.stringify( activeFilter.facetsDiff ) );
          facetsDiffCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });

          let modified: boolean =
            (activeFilter.title !== activeFilter.titleOriginal) ||
            (activeFilter.q !== activeFilter.qOriginal) ||
            (JSON.stringify(storeFacetsCleaned) !== JSON.stringify(facetsDiffCleaned));

          this.updateActiveFilterParameter(storeKey, 'filterModified', modified);
        });

      }

      // BUG: this was causing modified filters to appear as not modified on interval update,
      // does not appear to be needed, not sure why it is here
      // else {
      //   // console.log(`testActiveFilterModified(${storeKey})`, 'filterModified = false');
      //   // this.updateActiveFilterParameter(storeKey, 'filterModified', false);
      // }

    }

  }

  updateDefaultFilterId(storeKey: string, id: number): void {

    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.updateReportsDefaultFilterId(id);

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {
        this.updateLivefeedDefaultFilterId(id);

      }

    }

  }

  updateFacetsPrettyText(storeKey: string, text: string): void {
    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.updateReportsFacetsPrettyText(text);
    } else {
      if (livefeedStores.indexOf(storeKey) >= 0) {
        this.updateLivefeedFacetsPrettyText(text);
      }
    }
  }

  updateChipPrettyText(storeKey: string, text: string): void {
    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.updateReportsChipPrettyText(text);
    } else {
      if (livefeedStores.indexOf(storeKey) >= 0) {
        this.updateLivefeedChipPrettyText(text);
      }
    }
  }


}

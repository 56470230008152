<div class="iv-chatbot-message__wrapper" [ngClass]="{
    'chatterbot': isChatterBotAuthor,
    'user': !isChatterBotAuthor,
    'creative' : conversationStyle.value === CONVERSATION_STYLE.CREATIVE,
    'balanced' : conversationStyle.value === CONVERSATION_STYLE.BALANCED,
    'precise' : conversationStyle.value === CONVERSATION_STYLE.PRECISE,
  }">
  <img *ngIf="isChatterBotAuthor"
        alt="Enverus Logo"
        ngSrc="/assets/enverus-logo-black.svg"
        class="iv-chatbot-message__author-icon"
        height="93" width="120"/>
  <div [ngClass]="errorStatusCode > 0 ? 'iv-chatbot-message__error-body' : 'iv-chatbot-message__text-body'">
    <span>{{ messageText }}<ng-container *ngTemplateOutlet="loader"/></span>
    <ng-container *ngTemplateOutlet="references"></ng-container>
    <ng-container *ngIf="isChatterBotAuthor">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </ng-container>
  </div>
  <mat-icon *ngIf="!isChatterBotAuthor" class="iv-chatbot-message__author-icon">person</mat-icon>
</div>

<ng-template #references>
  <ng-content select="[references]"></ng-content>
</ng-template>

<ng-template #actions>
  <ng-content select="[actions]"></ng-content>
</ng-template>

<ng-template #loader>
  <ng-content select="[loader]"></ng-content>
</ng-template>

import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import {AuthGuardService} from './services/ivauth/auth-guard.service';
import {CallbackComponent} from './pages/callback/callback.component';
import {LoginComponent} from './pages/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {SummaryLivefeedComponent} from './pages/summary-livefeed/summary-livefeed.component';
import {ResearchDetailComponent} from './pages/research-detail/research-detail.component';
import {researchDetailResolver} from './pages/research-detail/research-detail.resolver';
import {InteractiveReportComponent} from './pages/interactive-report/interactive-report.component';
import {InteractiveReportResolver} from './pages/interactive-report/interactive-report.resolver';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {FourOFourPageComponent} from './pages/four-o-four-page/four-o-four-page.component';
import {Subscription} from "rxjs";

import {summaryMetadataResolver} from "./resolvers/summary-metadata.resolver";
import {PreviewRestrictedComponent} from './pages/preview-restricted/preview-restricted.component';
import {HelpComponent} from './pages/help/help.component';
import {PreferencesComponent} from './pages/preferences/preferences.component';
import {DownloadComponent} from './pages/download/download.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'error/:code',
    component: ErrorPageComponent,
  },
  {
    path: '404',
    component: FourOFourPageComponent,
  },
  {
    path: 'preview',
    component: PreviewRestrictedComponent,
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    component: DashboardComponent,
  },
  {
    path: 'preferences',
    canActivate: [AuthGuardService],
    component: PreferencesComponent,
  },
  {
    path: 'help',
    canActivate: [AuthGuardService],
    component: HelpComponent,
  },
  {
    path: 'research/:docId',
    canActivate: [AuthGuardService],
    component: ResearchDetailComponent,
    resolve: {
      metadata: researchDetailResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'research/:docId/interactive',
    component: InteractiveReportComponent,
    canActivate: [AuthGuardService],
    resolve: {
      metadata: InteractiveReportResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'research/:docId/:section',
    component: ResearchDetailComponent,
    canActivate: [AuthGuardService],
    resolve: {
      metadata: researchDetailResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'summary/livefeed/:docId',
    component: SummaryLivefeedComponent,
    resolve: {
      data: summaryMetadataResolver
    },
  },
  {
    path: 'download',
    canActivate: [AuthGuardService],
    component: DownloadComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    // enableTracing: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  debug_routes: boolean = false;        // show debug routing information
  private routerSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private router: Router
  ) {

    // show debug routing information if enabled
    if (this.debug_routes) {
      this.routerSubscription = router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          console.log('Navigation started');
          console.log(event);
        }
        if (event instanceof NavigationEnd) {
          console.log('Navigation ended');
          console.log(event);
        }
        if (event instanceof NavigationError) {
          console.log('Navigation error', event.error);
          console.log(event);
        }
        if (event instanceof NavigationCancel) {
          console.log('Navigation canceled');
          console.log(event);
        }
      });
    }

  }

  ngOnDestroy() {

    if (this.debug_routes) {
      if (this.routerSubscription !== Subscription.EMPTY) {
        this.routerSubscription.unsubscribe();
      }
    }

  }

}

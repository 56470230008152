import { Injectable } from '@angular/core';
import { Subject, Observable, Observer }  from 'rxjs';
//https://github.com/pladaria/reconnecting-websocket
import ReconnectingWebSocket from './reconnecting-websocket';
@Injectable()
export class WebsocketService {
  constructor() {}

  private subject: Subject<MessageEvent> | undefined;
  private ws: any;

  public connect(url: any): Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log('Successfully connected: ' + url);
    }
    return this.subject;
  }

  private create(url: any): Subject<MessageEvent> {
    const authToken = window.sessionStorage.getItem('access_token') ?? '';
    
    const options = {
      connectionTimeout: 5000,
      maxRetries: 0,
      debug: false
    };
    this.ws = new ReconnectingWebSocket(url, authToken, options);

    const observable = new Observable(
      (obs: Observer<MessageEvent>) => {
        this.ws.onmessage = obs.next.bind(obs);
      }
    );

    const observer = {
      next: (data: unknown) => {
        console.log("next: " + this.ws.readyState)
        this.ws.send(JSON.stringify(data));
        if (this.ws.readyState === 3) {
          this.ws.reconnect()
        }
      },
    };

    return Subject.create(observer, observable);
  }
}

import {Component, HostBinding, inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

import {NgProgressModule} from 'ngx-progressbar';
import {environment as ENV} from '../../../environments/environment';

import {Subscription} from 'rxjs';

import {Constants} from "../../constants/constants";

// import { AzureSearchService } from "../../services/azuresearch/azuresearch.service";
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {SavedFiltersService} from '../../services/savedfilters/savedfilters.service';
import {SnackbarArticlesService} from "../../services/snackbararticles/snackbararticles.service";
// import { SearchService } from "../../services/search/search.service";

import {LivefeedNavComponent} from '../livefeed-nav/livefeed-nav.component';
import {NavbarComponent} from '../../features/navbar/navbar.component';
import {ReportsNavComponent} from '../reports-nav/reports-nav.component';
import {ResearchDetailComponent} from '../research-detail/research-detail.component';
import {ResearchSpotlightComponent} from '../../features/research-spotlight/research-spotlight.component';
import {WidgetsAreaComponent} from '../../features/widgets-area/widgets-area.component';
import {MatIconModule} from '@angular/material/icon';
import {LogService} from '../../services/log/log.service';

@Component({
  selector: 'iv-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    LivefeedNavComponent,
    NavbarComponent,
    NgProgressModule,
    ReportsNavComponent,
    ResearchDetailComponent,
    ResearchSpotlightComponent,
    WidgetsAreaComponent,
    MatIconModule
  ],
  // providers: [SearchService],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {

  // private azureSearchService = inject(AzureSearchService);
  // public searchService = inject(SearchService);

  // main.service: controls main layout mode
  mainLayoutModeSub: Subscription = Subscription.EMPTY;
  mainLayoutMode: string = '';
  @HostBinding('class') class = 'reports';

  // private articleIntervalId: any = null;
  // private articleSnackBarRef: MatSnackBarRef<SnackbarArticleComponent> | null = null;

  constructor(
    private route: ActivatedRoute,
    public dashboardService: DashboardService,
    private savedFiltersService: SavedFiltersService,
    private snackBar: MatSnackBar,
    private snackbarArticlesService: SnackbarArticlesService,
    // public searchService: SearchService
    private logService:LogService
  ) {
  }

  ngOnInit(): void {

    this.route.data.subscribe(() => {
      //this.getRouteData();
      if (typeof (this.route.snapshot.params['docId']) !== 'undefined') {
        this.dashboardService.updateResearchDetailsDocId(this.route.snapshot.params['docId']);
        this.dashboardService.updateMainLayoutMode('ResearchDetail');
      }
    });

    this.mainLayoutModeSub = this.dashboardService.mainLayoutMode.subscribe(value => {
      this.mainLayoutMode = value;
    });

    // // update new articles snackbar
    // const nextArticle = this.snackbarArticlesService.getNextArticle();
    // if (nextArticle) {
    //   this.articleSnackBarRef = this.snackBar.openFromComponent(SnackbarArticleComponent, {
    //     data: nextArticle,
    //     panelClass: ['article-snackbar']
    //   });
    // }
    // this.articleIntervalId = setInterval(() => {
    //   const nextArticle = this.snackbarArticlesService.getNextArticle();
    //   if (nextArticle) {
    //     this.articleSnackBarRef = this.snackBar.openFromComponent(SnackbarArticleComponent, {
    //       data: nextArticle,
    //       panelClass: ['article-snackbar']
    //     });
    //   } else {
    //     if(this.articleSnackBarRef) {
    //       this.articleSnackBarRef.dismiss();
    //     }
    //   }
    // }, (Constants.snackbarRotateSeconds * 1000));

  }

  switchDashboardView($event: any) {
    if (this.class === 'reports') {
      this.class = 'livefeed';
      this.logService.logPendo('Dashboard Change', {
        title: 'Live Feed',
      });
    } else {
      this.class = 'reports';
      this.logService.logPendo('Dashboard Change', {
        title: 'Reports',
      });
    }
  }

  ngOnDestroy() {
    this.mainLayoutModeSub.unsubscribe();
    // if(this.articleIntervalId !== null) {
    //   clearInterval(this.articleIntervalId);
    // }
    // if(this.articleSnackBarRef) {
    //   this.articleSnackBarRef.dismiss();
    // }
  }

}

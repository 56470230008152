<section>
  <header>
    <div class="tab-bar">
      <h1>
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <circle cx="256" cy="256" r="256" fill="#58595b"/>
            <g transform="matrix(4.064 0 0 4.064 54.344332 54.270366)" fill="#f3f6f7">
              <circle cx="49.62" cy="49.63" r="7.66"/>
              <path d="M66.23 86.05c-.56 0-1.1-.32-1.35-.85-.36-.75-.04-1.64.71-2 12.9-6.15 21.23-19.33 21.23-33.56s-8.33-27.41-21.23-33.56c-.75-.36-1.07-1.25-.71-2s1.25-1.06 2-.71c13.94 6.65 22.94 20.88 22.94 36.27s-9.01 29.62-22.94 36.27c-.21.1-.43.15-.64.15zm-33.23 0a1.43 1.43 0 0 1-.64-.15C18.42 79.25 9.42 65.02 9.42 49.63s9.01-29.62 22.94-36.27c.75-.35 1.64-.04 2 .71s.04 1.64-.71 2c-12.9 6.15-21.23 19.33-21.23 33.56s8.33 27.41 21.23 33.56c.75.36 1.07 1.25.71 2a1.49 1.49 0 0 1-1.35.85zm26.47-14.72c-.37 0-.73-.21-.9-.57a.99.99 0 0 1 .47-1.33c7.61-3.63 12.52-11.4 12.52-19.79s-4.92-16.17-12.52-19.8c-.5-.24-.71-.83-.47-1.33s.83-.71 1.33-.47c8.3 3.96 13.66 12.44 13.66 21.6S68.2 67.28 59.9 71.24c-.14.07-.29.1-.43.1zm-19.7 0c-.14 0-.29-.03-.43-.1-8.3-3.96-13.66-12.44-13.66-21.6s5.36-17.64 13.66-21.6a.997.997 0 0 1 .86 1.8c-7.61 3.63-12.52 11.4-12.52 19.8S32.6 65.8 40.2 69.43c.5.24.71.83.47 1.33-.17.36-.53.57-.9.57z" fill="#f3f6f7"/>
            </g>
          </svg>
        </div>
        <div class="title">Live Feed</div>
        <div class="spacer"></div>
        <div class="circle"></div>
      </h1>
    </div>
    <div class="search-bar">
      <input placeholder="Search" aria-label="Search" [formControl]="searchCtrl" (keyup.enter)="searchService.navigateToPage(activeStoreKey, searchCtrl.value, 1); sharedService.updateActiveFilterParameter(activeStoreKey, 'q', searchCtrl.value); searchService.checkActiveFilterChanged(activeStoreKey, '')">
      <button (click)="searchService.navigateToPage(activeStoreKey, searchCtrl.value, 1)" matTooltip="Search">
        <mat-icon>keyboard_return</mat-icon>
      </button>
      <button (click)="clearSearchText($event)" class="searchresults" matTooltip="Cancel">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
    <nav>
      <mat-button-toggle-group #livefeedCollectionFilter="matButtonToggleGroup" value="{{activeStoreKey}}" (change)="setCollection(livefeedCollectionFilter.value)">
        <mat-button-toggle value="LiveFeedAll">All <span class="badge" *ngIf="tabCounts['LiveFeedAll'].count > 0">{{tabCounts['LiveFeedAll'].count}}</span></mat-button-toggle>
        <mat-button-toggle value="News">News <span class="badge" *ngIf="tabCounts['News'].count > 0">{{tabCounts['News'].count}}</span></mat-button-toggle>
        <mat-button-toggle value="GlobalScouting">Global Scouting <span class="badge" *ngIf="tabCounts['GlobalScouting'].count > 0">{{tabCounts['GlobalScouting'].count}}</span></mat-button-toggle>
        <mat-button-toggle value="AnalystTakes">Analyst Takes <span class="badge" *ngIf="tabCounts['AnalystTakes'].count > 0">{{tabCounts['AnalystTakes'].count}}</span></mat-button-toggle>
        <!--        <mat-button-toggle value="LiveFeedNew" *ngIf="tabCounts['LiveFeedNew'].count > 0">New <span *ngIf="tabCounts['LiveFeedNew'].count > 0">({{tabCounts['LiveFeedNew'].count}})</span></mat-button-toggle>-->
      </mat-button-toggle-group>
      <mat-select [(value)]="activeStoreKey" (selectionChange)="setCollection($event.value)">
        <mat-option value="LiveFeedAll">All <span class="badge" *ngIf="tabCounts['LiveFeedAll'].count > 0">({{tabCounts['LiveFeedAll'].count}})</span></mat-option>
        <mat-option value="News">News <span class="badge" *ngIf="tabCounts['News'].count > 0">({{tabCounts['News'].count}})</span></mat-option>
        <mat-option value="GlobalScouting">Global Scouting <span class="badge" *ngIf="tabCounts['GlobalScouting'].count > 0">({{tabCounts['GlobalScouting'].count}})</span></mat-option>
        <mat-option value="AnalystTakes">Analyst Takes <span class="badge" *ngIf="tabCounts['AnalystTakes'].count > 0">({{tabCounts['AnalystTakes'].count}})</span></mat-option>
      </mat-select>
      <div class="filters">
        <!--        <button class="tab-toolbar-button" (click)="showFilterHelp($event)">-->
        <!--          <mat-icon>help_outline</mat-icon>-->
        <!--        </button>-->
        <!--        <button [matMenuTriggerFor]="searchSort" class="tab-toolbar-button">-->
        <!--          <mat-icon>sort</mat-icon>-->
        <!--        </button>-->
        <!--        <mat-menu #searchSort="matMenu" [overlapTrigger]="false" class="sort-menu">-->
        <!--          <mat-button-toggle-group #order="matButtonToggleGroup" [value]="orderBy" [vertical]="true" (change)="setOrderBy(order.value)">-->
        <!--            <mat-button-toggle value="">-->
        <!--              <span>Relevance</span>-->
        <!--            </mat-button-toggle>-->
        <!--            <mat-button-toggle value="dateDesc">-->
        <!--              <span>Date Descending</span>-->
        <!--            </mat-button-toggle>-->
        <!--          </mat-button-toggle-group>-->
        <!--        </mat-menu>-->
        <button *ngIf="activeStoreKey!=='LiveFeedNew'" class="button-filters-new" (click)="showFilters($event)" title="{{facetsPrettyText}}">
          <div class="centered-content">
            Filter Results ({{searchService.filterCount(activeStoreKey)}})
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <circle cx="256" cy="256" r="240.845" fill="#f3f6f7" stroke="#57b947" stroke-width="15" paint-order="normal"/>
              <path d="M370.252 114.939H141.743c-4.602 0-7.254 5.243-4.542 8.961l90.678 123.444c.7.976 1.097 2.134 1.097 3.322v146.395h45.903V250.788c0-1.28.426-2.53 1.219-3.505l98.542-123.2c2.956-3.688.335-9.144-4.389-9.144z" fill="#58b947"/>
            </svg>
          </div>
        </button>
      </div>
    </nav>
  </header>
  <ng-container *ngIf="activeStoreKey!=='LiveFeedNew'">
    <iv-livefeed-view [store]="livefeedNavAzssData"></iv-livefeed-view>
  </ng-container>
  <ng-container *ngIf="activeStoreKey==='LiveFeedNew'">
    <iv-new-research [store]="livefeedNavAzssData"></iv-new-research>
  </ng-container>
  <div *ngIf="showLivefeedFacets" style="float: right; min-width: 300px; padding-left: 10px;">
    <iv-search-filters [pane]="'Livefeed'" [store]="searchService.livefeedAzss$ | async" [state]="researchItem" [defaultFilterId]="sharedService.livefeedDefaultFilterId$ | async" class="left"></iv-search-filters>
  </div>
</section>

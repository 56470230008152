import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {catchError, of} from 'rxjs';
import {IvauthService} from '../../services/ivauth/ivauth.service';

export const researchDetailResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const id = route.paramMap.get('docId');
  const router = inject(Router);
  const ivauth = inject(IvauthService);
  return inject(DataService).getResearchMetaData(id).pipe(
    catchError((error) => {
      let errorPage = '/error';
      switch (error.status) {
        case 404:
          errorPage = '/not-found';
          break;
        case 401:
        case 403:
          if (!ivauth.isAuthenticated()) {
            errorPage = '/login';
          }
          errorPage = '/error';
          break;
        default:
          break;
      }
      router.navigate([errorPage], {skipLocationChange: true});
      return of(error);
    })
  );
};

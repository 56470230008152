<div class="dialog-title">
  <span>Share Filter via Email</span>
  <button (click)="closeShareDialog()">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div class="container">
<form [formGroup]="shareForm" (ngSubmit)="onSubmit()" *ngIf="!success">
  <section>
    <label for="emails"><span>*</span> Email Address(es) to share to:</label>
    <input id="emails" matInput type="text" formControlName="emails" placeholder="xxx@example.com,yyy@example.com">
    <p *ngIf="f.emails.invalid" class="error">A least one email is required. Multiple emails should be seperated with a comma (,)</p>
  </section>
  <section>
    <label for="message">Optional Message:</label>
    <textarea id="message" matInput formControlName="message" rows="4" placeholder="Optional Message for the recipients of the shared filter."></textarea>
  </section>
  <p class="beta">Please note: This service currently does not provide any notification of invalid email addresses, they will simply be ignored. Please ensure the emails provided here are checked for typos or other mistakes. We are working to provide this functionality in an future update.</p>
  <div class="form-buttons">
    <div>
      <button mat-button type="submit">Submit</button>
      <button mat-button type="button" class="cancel-button" (click)="closeShareDialog()">Cancel</button>
    </div>
    <button type="button" class="content-copy" matTooltip="Click here to copy a direct link for the filter to your clipboard" matTooltipClass="sf-actions-tooltip" (click)="copyToClipboard()">
      <mat-icon #copyIcon>content_copy</mat-icon>
    </button>
  </div>
</form>
<div class="error" *ngIf="error">
  <h2>We're Sorry,</h2>
  <p>An error has occurred trying to process your request.</p>
  <p>This error may be temporary so you may wish to retry the submission in a few minutes.</p>
  <p>If the error continues to occur please contact <a href="mailto:EnergyAnalyticsSupport@enverus.com">EnergyAnalyticsSupport@enverus.com</a> directly.</p>
  <p>Thank you.</p>
</div>
<div class="success" *ngIf="success">
  <h2>Thank you!</h2>
  <p>You have shared the filter:</p>
  <p><strong>{{data.filter.title}}</strong></p>
  <p>With the following people: {{emailList}} </p>
</div>
<div *ngIf="success || error">
  <button mat-button class="close" (click)="closeShareDialog()">Close</button>
</div>

import { Injectable } from '@angular/core';
import {BehaviorSubject, first, Observable, of, Subject} from "rxjs";

import { Constants } from "../../constants/constants";
const livefeedStores: string[] = Constants.LivefeedStores;
const reportsStores: string[] = Constants.ReportsStores;
const researchSpotlightStores: string[] = Constants.ResearchSpotlightStores;

import { AzureSearchService } from "../azuresearch/azuresearch.service";
import { DataService } from "../data/data.service";
import { SavedFiltersService } from "../savedfilters/savedfilters.service";
import { SearchService } from "../search/search.service";
import { SharedService } from "../shared/shared.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  // layout control
  readonly mainLayouts: string[] = [ 'Panes', 'ResearchDetail' ];
  private _mainLayoutMode: BehaviorSubject<string> = new BehaviorSubject<string>('Panes');

  // livefeed observables
  // private _livefeedTab: BehaviorSubject<string> = new BehaviorSubject<string>(livefeedStores[0]);
  private _showLivefeedFacets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private clearLivefeedSearchBoxSubject: Subject<boolean> = new Subject<boolean>();
  public clearLivefeedSearchBox$: Observable<boolean> = this.clearLivefeedSearchBoxSubject.asObservable();

  // reports observables
  // private _reportsTab: BehaviorSubject<string> = new BehaviorSubject<string>(reportsStores[0]);
  private _showReportFacets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private clearReportsSearchBoxSubject: Subject<boolean> = new Subject<boolean>();
  public clearReportsSearchBox$: Observable<boolean> = this.clearReportsSearchBoxSubject.asObservable();

  // research details
  private _researchDetailsDocId = new BehaviorSubject<string>('');
  researchDetailsDoc$: Observable<any> = of({});

  constructor(
    private azureSearchService: AzureSearchService,
    private dataService: DataService,
    private savedFiltersService: SavedFiltersService,
    private searchService: SearchService,
    private sharedService: SharedService,
  ) {

    // initialize panes
    // this.sharedService.updateReportsTab(reportsStores[0]);

    // this.sharedService.reportsTab$.pipe(
    //   first()
    // ).subscribe((reportsTab: string) => {
    // });

    // initialize stores for all panes
    let stores: string[] = reportsStores.concat(livefeedStores).concat(researchSpotlightStores);
    this.searchService.init_stores(stores);


  }

  // --------------------------------------------------------------------------
  //  Layout Control
  // --------------------------------------------------------------------------

  get mainLayoutMode() {
    return this._mainLayoutMode.asObservable();
  }

  updateMainLayoutMode(mode: string) {
      if(this.mainLayouts.includes(mode)) {
        this._mainLayoutMode.next(mode);
      }
  }

  // --------------------------------------------------------------------------
  //  Reports Pane
  // --------------------------------------------------------------------------

  // get reportsTab() {
  //   return this._reportsTab.asObservable();
  // }

  // updateReportsTab(value: string) {
  //   this._reportsTab.next(value);
  // }

  get showReportFacets() {
    return this._showReportFacets.asObservable();
  }

  updateShowReportFacets(value: boolean) {
    this._showReportFacets.next(value);
  }

  clearReportsSearchBox() {
    this.clearReportsSearchBoxSubject.next(true);
  }

  // --------------------------------------------------------------------------
  //  Livefeed Pane
  // --------------------------------------------------------------------------

  // get livefeedTab() {
  //   return this._livefeedTab.asObservable();
  // }

  // updateLivefeedTab(value: string) {
  //   this._livefeedTab.next(value);
  // }

  get showLivefeedFacets() {
    return this._showLivefeedFacets.asObservable();
  }

  updateShowLivefeedFacets(value: boolean) {
    this._showLivefeedFacets.next(value);
  }

  clearLivefeedSearchBox() {
    this.clearLivefeedSearchBoxSubject.next(true);
  }

  // --------------------------------------------------------------------------
  //  Research Details
  // --------------------------------------------------------------------------

  get researchDetailsDocId() {
    return this._researchDetailsDocId.asObservable();
  }

  updateResearchDetailsDocId(docId: string) {
    if((typeof(docId) === 'string') && docId.length) {
      this._researchDetailsDocId.next(docId);
      this.updateResearchDetailsDoc(docId);
    }
  }

  // get researchDetailsDoc() {
  //   return this._researchDetailsDoc.asObservable();
  // }

  updateResearchDetailsDoc(docId: string) {
    if((typeof(docId) === 'string') && docId.length) {
      this.researchDetailsDoc$ = this.dataService.getResearchMetaData(docId);
    }
  }

  // --------------------------------------------------------------------------
  //  New Research
  // --------------------------------------------------------------------------

  // public ss_search_results_processor_track_new(storeKey: string, results: any): void {
  //   this.searchService.ss_search_results_processor(storeKey, results);
  //
  //
  //   // All: "(acl/any())",
  //   // Reports: "(collection/any(t: t eq 'Intelligence'))",
  //   // OperatorProfiles: "(collection/any(t: t eq 'Operator Profiles'))",
  //   // Downloads: "",
  //   // ReportsNew: "(acl/any()) and not (collection/any(t: t eq 'Live Feed'))",
  //   // LiveFeedAll: "(collection/any(t: t eq 'Live Feed'))",
  //   // News: "(collection/any(t: t eq 'Live Feed') and not (series eq 'Analyst Take') and not (acl/any(t: t eq 'acl:global-scout')))",
  //   // AnalystTakes: "(series eq 'Analyst Take')",
  //   // GlobalScouting: "(acl/any(t: t eq 'acl:global-scout'))",
  //   // LiveFeedNew: "(collection/any(t: t eq 'Live Feed'))",
  //   // ResearchSpotlight: "(displayRegion/any(t: t eq 'spotlight'))",
  //
  //   // if (Array.isArray(results)) {
  //   //   results.forEach(function (item) {
  //   //
  //   //
  //   //   });
  //   // }
  //
  // }

  clearSearchBox(storeKey: string): void {
    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.clearReportsSearchBox();

    } else {
      if( livefeedStores.indexOf(storeKey) >= 0 ) {
        this.clearLivefeedSearchBox();

      }
    }
  }

  clearAllFilters(storeKey: string): void {

    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.clearReportsSearchBox();
      this.sharedService.updateReportsSavedUrlFilter('');
      this.searchService.clearQueryParams(Constants.reportsSavedFiltersUrl);
      this.searchService.removeQueryParam(Constants.reportsSavedFiltersUrl);

    } else {
      if( livefeedStores.indexOf(storeKey) >= 0 ) {
        this.clearLivefeedSearchBox();
        this.sharedService.updateLivefeedSavedUrlFilter('');
        this.searchService.clearQueryParams(Constants.livefeedSavedFiltersUrl);
        this.searchService.removeQueryParam(Constants.livefeedSavedFiltersUrl);

      }
    }
    // this.searchService.showQueryParams();
    // this.searchService.ss_update_subscription_filters();
    this.searchService.setQuery(storeKey, '');
    this.searchService.setChipContents(storeKey, '');
    // this.searchService.ss_clear_search_box();
    this.searchService.setPage(storeKey, 1);
    this.azureSearchService.clear_all_facets(storeKey);
    this.savedFiltersService.clearFilters(storeKey);
    this.sharedService.clearActiveFilter(storeKey,
      this.azureSearchService.get_facetsdiff(storeKey, Constants.facetsModifiedIgnoreDates || false)
    );
  }

}

import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import {Subject} from "rxjs";

import { UserService } from "../../services/user/user.service";

declare const PDFTron: any;
declare let licenseKey: string;

@Component({
  selector: 'iv-pdftron',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './pdftron.component.html',
  styleUrls: ['./pdftron.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdftronComponent implements OnInit {

  onChanges = new Subject<SimpleChanges>();
  @Input() document: string = '';
  @Input() options: any = {};
  @ViewChild('viewer', {static: true}) viewer!: ElementRef;
  profile: any;
  preferences: any;
  webViewer: any;
  readonly disabledElements: any = ['themeChangeButton', 'downloadButton', 'printButton', 'toolbarGroup-Shapes', 'toolbarGroup-Edit', 'toolbarGroup-Insert', 'toolbarGroup-Annotate', 'toggleNotesButton', 'outlinesPanelButton', 'outlinesPanel'];

  constructor(
    private userService: UserService
  ) {
    this.profile = this.userService.getUserProfile();
    this.preferences = this.userService.getPreferences();
  }

  ngOnInit() {

    let acl = this.userService.getAcl();
    if (acl.indexOf("download") === -1) {
      this.disabledElements.push(['downloadButton', 'printButton'])
    }

    this.webViewer = new PDFTron.WebViewer({
      path: '/assets/pdftron/lib',
      initialDoc: this.document,
      l: atob(licenseKey),
      enableMeasurement: true,
      disabledElements: this.disabledElements,
      disableLogs: true
    }, this.viewer.nativeElement);

    this.viewer.nativeElement.addEventListener('documentLoaded', async () => {
      const instance = this.getInstance();
      if (this.preferences.theme === 'dark') {
        instance.setTheme('dark');
      }
      instance.disableDownload();
      instance.disableFeatures(instance.Feature.LocalStorage);
      instance.setFitMode(instance.FitMode.FitWidth);
      instance.setActiveLeftPanel('thumbnailsPanel')
      instance.setToolbarGroup('toolbarGroup-View');
      instance.setAnnotationUser(this.profile.firstname);
      this.configurePDFTron(this.options);
      this.onChanges.subscribe((changes: SimpleChanges) => {
        this.openDocument(changes['document'].currentValue);
        this.configurePDFTron(changes['options'].currentValue);
      })
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  configurePDFTron(options: any) {
    if (options) {
      const instance = this.getInstance();
      if (options.download) {
        instance.enableDownload();
      }
      // if(options.query){
      //   instance.searchTextFull(options.query.replace(/"/g, ""));
      //   instance.closeElements(['searchPanel']);
      // }
    }
  }

  openDocument(filepath: any) {
    if (filepath !== this.document) {
      this.getInstance().loadDocument(encodeURIComponent(filepath));
    }
  }

  getInstance(): any {
    return this.webViewer.getInstance();
  }

  getWindow(): any {
    return this.viewer.nativeElement.querySelector('iframe').contentWindow;
  }

  getElement(): any {
    return this.viewer.nativeElement;
  }

}

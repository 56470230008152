import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment as ENV} from '../../../environments/environment';
import {Observable} from 'rxjs';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private http: HttpClient) {
  }

  logPendo(event: any, params: any) {
    if (!ENV.review) {
      window.pendo.track(event, params);
    } else {
      return true;
    }
  }
}

import {Component, AfterViewInit, ViewChild, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
// import { MatBadgeModule } from "@angular/material/badge";
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggle, MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialog} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';

import {Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {filter, mergeMap, take} from 'rxjs/operators';

import {Constants} from '../../constants/constants';

const reportsStores: string[] = Constants.ReportsStores;

import {AzureSearchService} from '../../services/azuresearch/azuresearch.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {DataService} from '../../services/data/data.service';
import {ReportsNavService} from '../../services/reportsnav/reportsnav.service';
import {SavedFiltersService} from '../../services/savedfilters/savedfilters.service';
import {SearchService} from '../../services/search/search.service';
import {SharedService} from '../../services/shared/shared.service';
import {UserService} from '../../services/user/user.service';

import {DownloadsViewComponent} from '../../views/downloads-view/downloads-view.component';
import {NewResearchComponent} from '../new-research/new-research.component';
import {ReportsHelpComponent} from '../../dialogs/reports-help/reports-help.component';
import {ReportsViewComponent} from '../../views/reports-view/reports-view.component';
import {SearchFiltersComponent} from '../../features/search-filters/search-filters.component';

import {ActiveFilter, createEmptyActiveFilter} from '../../interface/activefilter';
import {AzssError, AzssStore} from '../../interface/azss';
import {Favorite} from '../../interface/favorite';
import {Research} from '../../interface/research';
import {environment as ENV} from '../../../environments/environment';
import * as constants from 'constants';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LogService} from '../../services/log/log.service';
import {ChatbotComponent} from '../../features/chatbot/chatbot.component';


@Component({
  selector: 'iv-reports-nav',
  standalone: true,
    imports: [
        CommonModule,
        MatAutocompleteModule,
        // MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
        ReactiveFormsModule,
        ReportsHelpComponent,
        ReportsViewComponent,
        SearchFiltersComponent,
        DownloadsViewComponent,
        NewResearchComponent,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        ChatbotComponent
    ],
  templateUrl: './reports-nav.component.html',
  styleUrls: ['./reports-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'navigation-tabs'}
    }]
})
export class ReportsNavComponent implements OnInit, OnDestroy, AfterViewInit {

  searchCtrl = new FormControl();
  clearReportsSearchBoxSub: Subscription = Subscription.EMPTY;
  blockSearchCtrlInfinite: boolean = false;

  // reportsTabSub: Subscription = Subscription.EMPTY;
  // reportsTab: string = '';
  // reportsTabUpdatable: boolean = true;  // setCollection() - prevent subscription infinite loop...

  // main.service: controls whether reports facets are displayed
  showReportFacetsSub: Subscription = Subscription.EMPTY;
  showReportFacets: boolean = false;

  // search.service (instance): subscription and data matching the active store in reports-nav
  reportsNavAzssData: any;  // AzssStore;
  reportsNavAzssSubscription: Subscription;

  reportsFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  research: Research[] = [];
  researchItem: Research | undefined;
  // state$: any;
  researchSubscription: Subscription = Subscription.EMPTY;

  activeFilterSub: Subscription = Subscription.EMPTY;
  activeFilter: ActiveFilter = createEmptyActiveFilter();

  filtersLoadedSub: Subscription = Subscription.EMPTY;
  // defaultFilterIdSub: Subscription = Subscription.EMPTY;
  defaultFilterId: number = 0;

  loadingSavedFilterSub: Subscription = Subscription.EMPTY;
  loadingSavedFilter: boolean = false;

  loadingSavedUrlFilterSub: Subscription = Subscription.EMPTY;
  loadingSavedUrlFilter: string = '';

  loadUrlFilterSub: Subscription = Subscription.EMPTY;

  // controls whether Reports pane is displaying search results or other pages
  showReportsSearchResults: boolean = true;

  // access the counts for new articles per tab
  tabCountsSub: Subscription = Subscription.EMPTY;
  tabCounts: any = {};

  activeStoreKey: string = '';  // reportsStores[0];

  blockInfinite: boolean = false;

  orderBy: string = 'dateDesc';

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  @ViewChild('reportsToggle') reportsToggle?: MatButtonToggle;

  favoritesSubscription: Subscription = Subscription.EMPTY;
  favoritesDb: Favorite[] = [];

  noResultsMsg: string = '';

  genaiChatBotPermission = false;

  constructor(private route: ActivatedRoute,
              public dialog: MatDialog,
              private azureSearchService: AzureSearchService,
              private dashboardService: DashboardService,
              private dataService: DataService,
              private reportsNavService: ReportsNavService,
              private savedFiltersService: SavedFiltersService,
              public searchService: SearchService,
              public sharedService: SharedService,
              public userService: UserService,
              private logService: LogService
  ) {

    // when search.service store/data is changed, get the new data for search results
    this.reportsNavAzssSubscription = this.searchService.reportsAzss$.subscribe((data: any) => {
      this.reportsNavAzssData = data;

      // also update an communication service so search-filters on the reports-nav pane gets updated
      this.reportsNavService.setReportsNavAzssData(data);
    });

    this.loadingSavedUrlFilterSub = this.sharedService.reportsSavedUrlFilter$.subscribe((data: any) => {
      this.loadingSavedUrlFilter = data;
    });

    this.loadUrlFilterSub = this.sharedService.reportsLoadUrl$.subscribe((urlkey: string) => {
      if ((typeof (urlkey) === 'string') && urlkey.length) {
        this.loadFilterUrl(urlkey);
      }
    });

  }

  ngOnInit(): void {
    const user = this.userService.getUserPermissions();
    this.genaiChatBotPermission = user.permissions.includes('GENAI_CHATBOT');

    if (typeof (this.route.snapshot.queryParams[Constants.reportsPaneQuery]) !== 'undefined') {
      this.searchService.setQuery(this.activeStoreKey, this.route.snapshot.queryParams[Constants.reportsPaneQuery]);
      this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
        this.noResultsMsg = '';
      }).catch((error: any) => {
        this.noResultsMsg = error.message;
      });
    }

    this.clearReportsSearchBoxSub = this.dashboardService.clearReportsSearchBox$.subscribe(value => {
      if (this.searchCtrl && value) {
        this.searchCtrl.setValue('');  // NEW
      }
    });

    this.showReportFacetsSub = this.dashboardService.showReportFacets.subscribe(value => {
      this.showReportFacets = value;
    });

    this.tabCountsSub = this.searchService.newCounts.subscribe(value => {
      this.tabCounts = value;
    });

    this.activeFilterSub = this.sharedService.reportsActiveFilter$.subscribe(af => {
      this.activeFilter = af;
      if (!this.blockSearchCtrlInfinite) {
        if ((this.activeStoreKey === af.collection) && af.hasOwnProperty('q') && (af.q !== undefined)) {
          this.blockSearchCtrlInfinite = true;
          setTimeout(() => {
            // this.azureSearchService.dump_stores(this.activeStoreKey);
            this.searchCtrl.setValue(this.activeFilter.q);
            this.blockSearchCtrlInfinite = false;
          });
        }
      }

      // if( (this.activeStoreKey === this.activeFilter.collection) && (this.activeFilter.id > 0) ) {
      //   this.savedFiltersService.loadFilter(this.activeStoreKey, this.activeFilter.id);
      // }

    });

    this.loadingSavedFilterSub = this.sharedService.reportsLoadingSavedFilter$.subscribe(value => {
      this.loadingSavedFilter = value;
    });

    this.favoritesSubscription = this.userService.userFavorites$.subscribe((favorites: Favorite[]) => {
      this.favoritesDb = favorites;

      let azssError: any;
      let searchParameters: Object = {};
      let searchParametersNew: any = Object.assign({}, searchParameters);
      let idCount: number = 0;
      searchParametersNew.additionalFilters = `(id eq '01802467436734763482963946783428068374026783456')`;

      // need to ensure we are not requesting a page beyond what is available... for instance if you have 20 per page
      // and you are on page 3 and delete the 41st bookmark then future queries will fail as it is still trying
      // to request results for page 3
      let searchParametersOld = this.azureSearchService.get_search_parameters('Bookmarks');
      if ((this.favoritesDb.length > 0) && (searchParametersOld.skip >= this.favoritesDb.length)) {
        searchParametersNew.skip = searchParametersOld.skip - searchParametersOld.top;
      }
      if (this.favoritesDb.length) {
        searchParametersNew.additionalFilters = `(`;
        this.favoritesDb.forEach(function (favorite: Favorite) {
          searchParametersNew.additionalFilters += `${idCount > 0 ? ' or ' : ''}(id eq '${favorite.id}')`;
          idCount += 1;
        });
        searchParametersNew.additionalFilters += `)`;
      }
      // else {
      //   this.sharedService.updateReportsTab(Constants.ReportsStores[0]);
      //   //this.reportsTab = Constants.ReportsStores[0];
      //   this.activeStoreKey = Constants.ReportsStores[0];
      //   if (this.reportsToggle) {
      //     this.activateToggle(this.reportsToggle);
      //   }
      // }

      if ((azssError = this.azureSearchService.update_search_parameters('Bookmarks', searchParametersNew))) {
      }

      if (this.activeStoreKey === 'Bookmarks') {
        this.azureSearchService.search('Bookmarks');
      }

    });

    this.reportsFacetsPrettyTextSubscription = this.sharedService.reportsFacetsPrettyText$.subscribe((text: string) => {
      this.facetsPrettyText = text;
    });

    let defaultFilterIdSet = false; // Flag to track if defaultFilterId is set
    this.filtersLoadedSub = this.savedFiltersService.filtersLoaded$.pipe(
      filter(filtersLoaded => filtersLoaded), // Wait until filtersLoaded is true
      switchMap(() => this.processLoadedFiltersAndSelectDefault().pipe(
        tap((defaultFilterId: number) => {
          if (this.loadingSavedUrlFilter === '') {
            this.defaultFilterId = defaultFilterId;
            defaultFilterIdSet = true; // Set the flag when defaultFilterId is set
          }
        })
      )),
      mergeMap(() => this.sharedService.reportsTab$) // reportsTabSub subscription occurs after this point
    ).subscribe(value => {

      this.activeStoreKey = value;

      // BUG: this is actually fired 3 times! when coming with http://localhost:4200/dashboard?rf=CT94JRI5-piapA
      // and an active filter on the Reports tab

      if (!this.blockInfinite) {

        if (this.loadingSavedUrlFilter !== '') {
          const urlkey = this.route.snapshot.queryParams[Constants.reportsSavedFiltersUrl];

          this.loadingSavedUrlFilter = '';

          if ((typeof (urlkey) === 'string') && urlkey.length) {
            this.loadFilterUrl(urlkey);
          }

        } else {

          if (defaultFilterIdSet) {
            if (this.defaultFilterId > 0) {
              this.sharedService.updateReportsLoadingSavedFilter(true);
            }
            this.setCollection(value);
            if (this.defaultFilterId > 0) {
              this.sharedService.updateReportsLoadingSavedFilter(false);
            }
            // this.reportsTab = value;
            this.activeStoreKey = value;
          }

        }

      }

    });

    // this.showLivefeedFacetsSub = this.dashboardService.showLivefeedFacets.subscribe(value => {
    //   this.showLivefeedFacets = value;
    // });

    // this.searchCtrl.valueChanges.subscribe((val) => {
    //   if (val) {
    //     this.searchService.suggest(val);
    //   }
    // });

  }

  loadFilterUrl(urlkey: string): void {

    this.dataService.loadFilterUrl(urlkey).then(result => {

      let filter = result.result.filter.filter;
      let q = filter.q;

      // set the facets to the loaded filter
      this.blockInfinite = true;
      this.sharedService.updateReportsTab(filter.collection);
      this.azureSearchService.clear_all_facets(filter.collection, false);
      this.azureSearchService.set_facets_diff(filter.collection, filter.facetsdiff);
      this.searchService.ss_set_subscription(filter.collection, '', false);
      this.azureSearchService.facetsDiff_to_facets(filter.collection, true);
      this.searchService.setQuery(filter.collection, q);
      this.searchService.ss_set_collection(filter.collection, true);
      //this.reportsTab = filter.collection;
      this.activeStoreKey = filter.collection;
      this.blockInfinite = false;

    });

  }

  ngAfterViewInit(): void {

    this.researchSubscription = this.searchService.research$.subscribe((research) => {
      this.research = research;
      const researchRec: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.activeStoreKey);
      if (researchRec !== undefined) {
        this.researchItem = JSON.parse(JSON.stringify(researchRec));
      }
      if (this.activeStoreKey === 'Chatbot') {
        const [research] = this.research.filter(({ activeStoreKey }) => activeStoreKey === 'Reports');
        if(research) {
          this.researchItem = JSON.parse(JSON.stringify(research));
        }
      }
    });

    this.searchCtrl.valueChanges.subscribe((val) => {
      if (!this.blockSearchCtrlInfinite) {
        if (typeof (val) === 'string') {
          if (this.autocomplete) {
            this.autocomplete.openPanel();
          }
          this.searchService.setQuery(this.activeStoreKey, val);
          if (Constants.suggestResults) {
            this.searchService.suggest(this.activeStoreKey, val);
          }
        }
      }
    });
  }

  activateToggle(toggle: MatButtonToggle) {
    if (toggle) {
      toggle.checked = true; // This will make the button toggle active
    }
  }

  processLoadedFiltersAndSelectDefault(): Observable<number> {
    // return of(this.savedFiltersService.getDefaultFilterIdByStore(reportsStores));
    return this.sharedService.reportsDefaultFilterId$;
  }

  public setCollection(collection: string) {

    if (reportsStores.indexOf(collection) >= 0) {

      // if an active filter, load it
      let activeFilterId: number = this.savedFiltersService.getActiveFilterId(collection);

      // otherwise if a default filter, load it (but only the first time!)
      if (activeFilterId === 0) {
        if (!this.savedFiltersService.getDefaultFilterLoaded(collection)) {
          this.savedFiltersService.setDefaultFilterLoaded(collection);
          activeFilterId = this.savedFiltersService.getDefaultFilterId(collection);
        }
      }

      if (activeFilterId > 0) {

        if (!this.loadingSavedFilter) {
          this.savedFiltersService.loadFilter(collection, activeFilterId);
        }

      } else {

        this.blockSearchCtrlInfinite = true;
        this.sharedService.clearActiveFilter(collection);
        const researchRec: Research | undefined = this.research.find(rec => rec.activeStoreKey === collection);
        if (researchRec !== undefined) {
          this.sharedService.updateActiveFilterParameter(collection, 'q', researchRec.q);
          this.sharedService.updateActiveFilterParameter(collection, 'collection', collection);
        }
        this.blockSearchCtrlInfinite = false;

        if (collection === 'ReportsNew') {
          this.dashboardService.updateShowReportFacets(false);
        }

        this.showReportsSearchResults = true;

        this.activeStoreKey = collection;

        if (!this.blockInfinite) {
          this.blockInfinite = true;
          this.sharedService.updateReportsTab(collection);
          this.blockInfinite = false;
        }

        if (!this.loadingSavedFilter) {
          this.searchService.ss_set_collection(collection);
        }

      }

    }


    // else {
    //
    //   if(collection === 'Bookmarks') {
    //     this.showReportsSearchResults = false;
    //     // this.showFilterPane = false;
    //     this.dashboardService.updateShowReportFacets(false);
    //   }
    // }

    this.logService.logPendo('Collection Change', {
      title: collection,
    });

  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
    this.searchService.ss_set_order_by(this.activeStoreKey, orderBy);
    this.userService.setPreferences({search: {orderby: orderBy}});
    this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
      this.noResultsMsg = '';
    }).catch((error: any) => {
      this.noResultsMsg = error.message;
    });
    // this.router.navigate(['/search'], {queryParams: {q: this.searchCtrl.value, page: 1}});
  }

  public showFilters($event: any) {
    if (this.showReportsSearchResults) {
      this.dashboardService.updateShowReportFacets(!this.showReportFacets);

      // this.dashboardService.updateResearchDetailsDocId('61115');
      // this.dashboardService.updateMainLayoutMode('ResearchDetail');
    }
  }

  public showFilterHelp($event: any) {
    const dialogRef = this.dialog.open(ReportsHelpComponent, {
      panelClass: 'iv-reports-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '300px',
      height: 'auto',
      data: ''
    });
  }

  // setShowReportsSearchResults(flag: boolean) {
  //   this.showReportsSearchResults = flag;
  // }

  public clearSearchText(event: any) {
    if (this.autocomplete) {
      this.autocomplete.openPanel();
    }
    this.searchService.setQuery(this.activeStoreKey, '');
    //this.searchService.setChipContents(this.store.collection, '');
    // this.updateKeywordChipContents('');
    this.sharedService.updateActiveFilterParameter(this.activeStoreKey, 'q', '');
    setTimeout(() => {
      this.searchCtrl.setValue('');  // NEW
    });
    this.searchService.navigateToPage(this.activeStoreKey, '', 1);
    this.searchService.checkActiveFilterChanged(this.activeStoreKey, '');
  }

  ngOnDestroy() {
    this.clearReportsSearchBoxSub.unsubscribe();
    this.reportsNavAzssSubscription.unsubscribe();
    if (this.researchSubscription !== Subscription.EMPTY) {
      this.researchSubscription.unsubscribe();
    }
    this.showReportFacetsSub.unsubscribe();
    this.tabCountsSub.unsubscribe();
    this.activeFilterSub.unsubscribe();
    this.filtersLoadedSub.unsubscribe();
    if (this.loadUrlFilterSub !== Subscription.EMPTY) {
      this.loadUrlFilterSub.unsubscribe();
    }
    // this.defaultFilterIdSub.unsubscribe();
    this.loadingSavedFilterSub.unsubscribe();
    this.favoritesSubscription.unsubscribe();
    this.reportsFacetsPrettyTextSubscription.unsubscribe();
  }

}

<div class="iv-chatbot">
  <div class="iv-chatbot" [ngClass]="{ 'iv-chatbot__disabled' : !allowedFeature || !disclosureAgreed}">
    <div class="iv-chatbot__header">
      <iv-intelligence-type [store]="store"></iv-intelligence-type>
      <iv-hallucination [conversationStyle]="conversationStyle" [allowedFeature]="allowedFeature"/>
    </div>
    <div id="iv-chatbot-content" class="iv-chatbot-content"
         #chatScroll
         [scrollTop]="chatScroll.scrollHeight">
      <ng-container message-clipboard #messageClipboard="d"></ng-container>
      <iv-rating-panel #ratingPanel></iv-rating-panel>
      <ng-container prompt-actions #promptActions="d"></ng-container>
      <ng-container *ngFor="let message of messageList">
        <iv-message [message]="message" [conversationStyle]="conversationStyle">
          <ng-container references *ngIf="message.quotedReferenceList?.length && message.quotedReferenceList?.length > 0">
            <iv-references [message]="message"></iv-references>
          </ng-container>
          <ng-container *ngIf="message?.errorStatusCode === 0 || message?.errorStatusCode === undefined || message?.errorStatusCode === null" actions>
            <mat-divider class="iv-chatbot-divider"/>
            <div class="iv-chatbot-actions" *ngIf="message.author === AUTHOR.CHATBOT">
              <div class="iv-chatbot-action-icon" matTooltip="Copy" matTooltipPosition="above">
                <iv-clipboard-icon (click)="messageClipboard.copyMessage(message)"></iv-clipboard-icon>
              </div>
              <div class="iv-chatbot-action-icon" matTooltip="Like" matTooltipPosition="above">
                <mat-icon (click)="ratingPanel.openPanel(message, uniqueId, true)">thumb_up</mat-icon>
              </div>
              <div class="iv-chatbot-action-icon" matTooltip="Dislike" matTooltipPosition="above">
                <mat-icon (click)="ratingPanel.openPanel(message, uniqueId,false)">thumb_down</mat-icon>
              </div>
              <!-- <div class="iv-chatbot-action-icon" matTooltip="Retry" matTooltipPosition="above">
                <mat-icon (click)="promptActions.retryPrompt(this, message.prompt_id)">refresh</mat-icon>
              </div> -->
            </div>
          </ng-container>
        </iv-message>
      </ng-container>
      <ng-container *ngIf="typing">
        <iv-message [message]="{ author: AUTHOR.CHATBOT, user_input: 'Thinking' }">
          <iv-thinking-loader loader/>
        </iv-message>
      </ng-container>
      <ng-container *ngIf="!typing && currentAnswer.response !== ''">
        <iv-message [message]="{ author: AUTHOR.CHATBOT, user_input: currentAnswer.response }">
          <iv-thinking-loader loader/>
        </iv-message>
      </ng-container>
    </div>
    <div *ngIf="disclosureAgreed" class="iv-chatbot__prompt-wrapper">
      <button mat-mini-fab color="primary" matTooltip="New Conversation" (click)="restartConversation()">
        <mat-icon>library_add</mat-icon>
      </button>
      <iv-prompt-input class="iv-chatbot__prompt" (emitPrompt)="sendUserMessage($event)" [textareaControl]="promptControl" [allowedFeature]="allowedFeature">
        <iv-token-tracker [usedToken]="usedToken"></iv-token-tracker>
      </iv-prompt-input>
    </div>
  </div>
  <div *ngIf="!allowedFeature && !clickedInterest" class="iv-chatbot__disabled-banner-wrapper">
    <p><strong>Instant Analyst</strong> is an interactive Enverus Intelligence™ AI providing real time insights into our research and publications. Instant Analyst requires an active subscription to our Generative AI offering. Are you interested in this feature?</p>
    <div>
      <button (click)="interested(true)">Yes</button>
      |
      <button (click)="interested(false)">No</button>
    </div>
  </div>
  <div *ngIf="!allowedFeature && clickedInterest" class="iv-chatbot__disabled-banner-wrapper">
    <p>Thank You for your Feedback.</p>
  </div>
</div>

<mat-expansion-panel *ngIf="playlist.length > 1 && currentItem">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Now Playing: ({{currentIndex + 1}} / {{playlist.length}})
    </mat-panel-title>
    <mat-panel-description>
      {{currentItem.title}}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="playlist-item" *ngFor="let item of playlist; let $index = index"
       [class.selected]="item === currentItem">
    <div (click)="onClickPlaylistItem(item, $index)">
      <mat-icon *ngIf="item !== currentItem">play_arrow</mat-icon>
      <mat-icon *ngIf="item === currentItem">equalizer</mat-icon>
      {{ item.title }}</div>
    <button type="button">
            fileSaver
            [method]="'GET'"
            [fileName]="getFilename(item.src)"
            [url]="item.src">
      <!--(success)="onSuc($event)"-->
      <!--(error)="onErr($event)"-->
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
</mat-expansion-panel>
<ng-container *ngIf="currentItem">
  <vg-player (onPlayerReady)="onPlayerReady($event)" *ngIf="playlist.length > 0"
             [ngClass]="{'audioWithCaptions': (currentItem.type === 'audio/mp3' || currentItem.type === 'audio/mpeg') && currentItem.cues.length > 0, 'audio': (currentItem.type === 'audio/mp3' || currentItem.type === 'audio/mpeg') && currentItem.cues.length === 0 }">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>
    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>
    <vg-controls [vgAutohide]="currentItem.type === 'video/mp4'" [vgAutohideTime]="3">
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>
      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
      <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
      <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
      <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-track-selector *ngIf="currentItem?.cues.length > 0"></vg-track-selector>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video #media
           [vgMedia]="$any(media)"
           [vgMaster]="true"
           [src]="currentItem.src"
           [poster]="'/assets/share/SharedStory_1.png'"
           id="video"
           preload="auto"
           crossorigin>
      <track *ngFor="let cue of currentItem?.cues" [kind]="cue.kind || 'subtitles'" [label]="cue.label || 'English'" [src]="cue.src" [srclang]="cue.lang" #metadataTrack vgCuePoints default>
    </video>
  </vg-player>
</ng-container>
<div id="transcript-preview">
<!--  <rsv-inline-loading text="Generating Media Transcript" [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '40px'}" *ngIf="currentItem?.transcript && !document"></rsv-inline-loading>-->
  <iv-pdftron *ngIf="document" id="pdftron" #pdftron [document]="document"></iv-pdftron>
</div>

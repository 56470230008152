import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { map } from 'rxjs/operators';
import {environment as ENV} from '../../../environments/environment';

const CHAT_URL = ENV.API.chatBot.websocket;

export interface Reference {
  published_date: string;
  reference_id: string;
  referenced: boolean;
  source: string;
  title: string;
  url: string;
}
export interface Message {
  action?: string;
  user_input: string;
  authorizer?: string;
  conversation_id?: string;
  author?: string;
  prompt_id?: string;
  references?: Reference[];
  quotedReferenceList?: Reference[];
  noQuotedReferenceList?: Reference[];
  ratingExpanded?: boolean;
  errorStatusCode?: number;
}

@Injectable()
export class ChatService {
  public messages: Subject<Message>;

  constructor(wsService: WebsocketService) {
    this.messages = <Subject<Message>>wsService.connect(CHAT_URL).pipe(
      map((response: MessageEvent): Message => {
        try {
          const data = JSON.parse(response.data);
          if (data.data.references) {
            return {
              author: 'chatbot',
              user_input: 'References',
              references: data.data.references,
            };
          }
          if (data.data.includes('##END_CONVERSATION##')) {
            return {
              author: 'chatbot-end',
              user_input: '',
              prompt_id: data.prompt_id,
            };
          }
          return {
            author: 'chatbot',
            user_input: data.data,
          };
        } catch (error) {
          console.log(error);
        }
        return {
          author: 'chatbot-error',
          user_input: 'Response Error',
        };
      })
    );
  }
}

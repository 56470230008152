import {Component, Input} from '@angular/core';
import {Message} from "../chat.service";
import {FormControl, FormsModule} from "@angular/forms";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {AUTHOR} from "../chatbot.component";
import {ThinkingLoaderComponent} from "../thinking-loader/thinking-loader.component";
import {CONVERSATION_STYLE} from "../hallucination/conversation-style";

@Component({
  selector: 'iv-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule, MatIconModule, NgOptimizedImage, ThinkingLoaderComponent],
})
export class MessageComponent {
  isChatterBotAuthor = true;
  messageText: string = '';
  errorStatusCode: number = 0;
  @Input() conversationStyle = new FormControl<CONVERSATION_STYLE>(CONVERSATION_STYLE.BALANCED);
  @Input() set message(message: Message) {
    const { author, user_input } = message;
    this.isChatterBotAuthor = author === AUTHOR.CHATBOT;
    this.messageText = user_input;
    if(message.hasOwnProperty('errorStatusCode') && (typeof(message.errorStatusCode) === 'number') && (message.errorStatusCode > 0)) {
      this.errorStatusCode = message.errorStatusCode;
    } else {
      this.errorStatusCode = 0;
    }
  }

  protected readonly CONVERSATION_STYLE = CONVERSATION_STYLE;
}

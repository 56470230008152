<article>
  <ng-container *ngIf="link && expiry >= Date">
    <section id="download">
      <h1>YOUR INTELLIGENCE DOWNLOAD IS READY
        <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
          <mat-icon svgIcon="back"></mat-icon>
        </button>
      </h1>
      <p>You can download your Enverus Intelligence® export by using the link below. This will only be available until {{ expiry | date:'full':'UTC' }}</p>
      <a href="{{link}}" class="download">Download Now</a>
      <p class="error">In case of a download failure, please contact <a href="mailto:EnergyAnalyticsSupport@enverus.com">EnergyAnalyticsSupport@enverus.com</a> directly.</p>
    </section>
  </ng-container>
  <ng-container *ngIf="!expiry || expiry < Date">
    <h1>We're sorry, this Enverus Intelligence® download link has expired.
      <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
        <mat-icon svgIcon="back"></mat-icon>
      </button>
    </h1>
    <p>Please try generating a new download queue by returning to the vault and selecting the files you wish to download.</p>
  </ng-container>
  <p>Return to Dashboard
    <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
  </p>
</article>

import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment as ENV} from '../../../environments/environment';

@Injectable()
export class CgwInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/cgw/')) {
      request = request.clone({
          headers: request.headers.append('intelligence-api-context', ENV.review ? 'Review' : 'Desktop')
        }
      );
    }
    return next.handle(request);
  }
}

<div id="logo">
  <a href="https://app.enverus.com/gallery" class="fullname" target="_blank" (click)="logPendoEvent('Gallery')">
    <img src="/assets/enverus-logo-white.svg"/></a>
  <span> &nbsp; | <a href="/dashboard" (click)="logPendoEvent('Dashboard')"><span class="fullname">Intelligence</span> Vault</a></span>
</div>
<div id="user" *ngIf="authenticated">
  <svg type="user" height="16px" width="16px" viewBox="0 0 24 24" fill-rule="evenodd" transform="" vector-effect="non-scaling-stroke" style="overflow: visible; pointer-events: auto;" [matMenuTriggerFor]="profileMenu">
    <path data-testid="rs-icon-test" d="M23.559,20.696c-0.317,-0.982 -0.891,-1.973 -1.738,-2.549c-1.63,-1.118 -3.529,-1.714 -5.365,-2.352c-0.443,-0.16 -0.893,-0.335 -1.298,-0.584c-0.362,-0.222 -0.497,-0.677 -0.581,-1.076c-0.037,-0.211 -0.06,-0.428 -0.072,-0.642c1.332,-1.881 2.215,-4.951 2.215,-7.469c0,-3.929 -2.149,-5.024 -4.8,-5.024c-2.651,0 -4.8,1.093 -4.8,5.022c0,2.608 0.947,5.807 2.358,7.665c-0.013,0.147 -0.029,0.294 -0.055,0.44c-0.084,0.399 -0.22,0.845 -0.581,1.067c-0.405,0.249 -0.855,0.407 -1.298,0.567c-1.836,0.638 -3.735,1.201 -5.365,2.318c-0.847,0.577 -1.421,1.635 -1.738,2.617c-0.326,1.019 -0.459,2.281 -0.439,3.304l23.996,0c0.02,-1.023 -0.113,-2.285 -0.439,-3.304Z" fill="#fff"></path>
  </svg>
  <mat-menu class="user-menu" #profileMenu="matMenu" yPosition="below" [overlapTrigger]="false">
    <button mat-menu-item routerLink='/help'>Help</button>
    <button mat-menu-item routerLink='/preferences'>Preferences</button>
    <button mat-menu-item (click)="showFeedback()">Feedback</button>
    <button mat-menu-item (click)="ivauth.logout()">Logout</button>
  </mat-menu>
</div>

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthService} from '@auth0/auth0-angular';
import {IvauthService} from '../../services/ivauth/ivauth.service';
import {ThemeService} from '../../services/theme/theme.service';

// import { UserService } from "../../services/user/user.service";

@Component({
  selector: 'iv-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  loadingAccount: boolean = false;
  accountError: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService, private ivauth: IvauthService,
              private themeService: ThemeService) {  // , private user: UserService
    this.route
      .queryParamMap
      .subscribe((params) => {

        if (params.get('prismframe') && params.get('theme')) {
          const theme = params.get('theme');
          if (theme === 'light' || theme === 'dark') {
            this.themeService.setTheme('prism ' + theme);
          }
        }

        if (params.get('error')) {
          const error = params.get('error');
          if (error) {
            switch (error) {
              case 'authentication': {
                this.accountError = 'We\'re Sorry! An error has occurred while trying to authenticate your Enverus account. Please try again later. If the problem persists please contact EnergyAnalyticsSupport@enverus.com';
                break;
              }
              case 'authorization': {
                this.accountError = 'We\'re Sorry! An authorization error has occurred. Please contact EnergyAnalyticsSupport@enverus.com';
                break;
              }
              case 'authorization_permissions': {
                this.accountError = 'We\'re Sorry! An authorization error has occurred. ' +
                  'This user account does not have Enverus Intelligence Permissions. ' +
                  'Please contact EnergyAnalyticsSupport@enverus.com';
                break;
              }
              default: {
                this.accountError = 'We\'re Sorry! An unknown error has occurred. Please contact EnergyAnalyticsSupport@enverus.com';
                break;
              }
            }
          }
        } else {
          this.loadingAccount = true;
          this.initSSO();
        }
      });
  }

  ngOnInit(): void {

    // Once the user is logged in @ auth0, the plugin emits an Observable that can capture the user profile.
    // We could capture it here but we want an extended version returned by gateway client /authorize call.

    // this.auth.user$.subscribe( profile => {
    //   // this.user.setUserProfile(profile);
    // });
  }

  initSSO() {

    if (this.ivauth.isAuthenticated()) {
      this.router.navigate(['/dashboard'], {replaceUrl: true, queryParamsHandling: 'preserve'});
    } else {
      this.ivauth.renewToken(true);
    }

    this.ivauth.ssoAuthComplete$.subscribe(
      (status) => {
        if (!status) {
          this.loadingAccount = status;
        }
      }
    );
  }

}

<section>
  <header>
    <div class="tab-bar">
      <h1>
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <circle cx="256" cy="256" r="256" fill="#58595b"/>
            <g fill="#f3f6f7">
              <g transform="matrix(3.0261409,0,0,3.1307659,102.23701,104.15586)">
                <path d="m 30.9,41.82 h 27.25 c 0.65,0 1.17,0.53 1.17,1.17 0,0.64 -0.52,1.17 -1.17,1.17 H 30.91 c -0.65,0 -1.18,-0.52 -1.18,-1.17 0,-0.65 0.52,-1.17 1.17,-1.18 z"/>
                <path d="m 63.87,44.16 c -0.65,0 -1.18,-0.52 -1.18,-1.17 0,-0.65 0.52,-1.17 1.17,-1.18 h 11.31 c 0.65,0 1.17,0.53 1.17,1.17 0,0.64 -0.52,1.17 -1.17,1.17"/>
                <path d="M 72.02,20.6 H 84.29 L 72.02,8.36 Z M 23.43,5.65 c -4.34,0 -7.86,3.52 -7.86,7.85 v 70.99 c 0,4.33 3.52,7.84 7.86,7.85 h 55.69 c 4.34,0 7.86,-3.52 7.86,-7.85 V 24.43 h -16.9 c -1.06,0 -1.92,-0.86 -1.92,-1.92 V 5.65 Z m 0,90.53 c -6.46,0 -11.7,-5.23 -11.7,-11.68 v -71 c 0,-6.45 5.25,-11.68 11.7,-11.68 h 46.23 c 0.79,0 1.54,0.3 2.09,0.86 l 18.21,18.18 c 0.56,0.55 0.87,1.3 0.86,2.09 v 61.54 c 0,6.45 -5.24,11.68 -11.7,11.68 H 23.43 Z"/>
                <path d="M 42.37,37.51 H 73.15 V 29.36 H 42.37 Z m -0.4,20.64 H 72.75 V 49.91 H 41.97 Z M 24.75,78.37 H 35.72 V 29.36 H 24.75 Z m 17.6,0 H 73.13 V 70.22 H 42.35 Z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="title">Reports</div>
        <div class="spacer"></div>
        <div class="circle"></div>
      </h1>
    </div>
    <div class="search-bar">
      <input placeholder="Search" aria-label="Search" [formControl]="searchCtrl" (keyup.enter)="searchService.navigateToPage(activeStoreKey, searchCtrl.value, 1); sharedService.updateActiveFilterParameter(activeStoreKey, 'q', searchCtrl.value); searchService.checkActiveFilterChanged(activeStoreKey, '')">
      <button (click)="searchService.navigateToPage(activeStoreKey, searchCtrl.value, 1)" matTooltip="Search">
        <mat-icon>keyboard_return</mat-icon>
      </button>
      <button (click)="clearSearchText($event)" class="searchresults" matTooltip="Cancel">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
    <div *ngIf="noResultsMsg.length" class="no-results">
      {{ noResultsMsg }}
    </div>
    <nav>
      <mat-button-toggle-group #collectionFilter="matButtonToggleGroup" value="{{activeStoreKey}}" (change)="setCollection(collectionFilter.value)">
        <mat-button-toggle #reportsToggle value="Reports">Reports <span class="badge" *ngIf="tabCounts['Reports'].count > 0">{{ tabCounts['Reports'].count }}</span></mat-button-toggle>
        <mat-button-toggle value="OperatorProfiles">Operator Profiles <span class="badge" *ngIf="tabCounts['OperatorProfiles'].count > 0">{{ tabCounts['OperatorProfiles'].count }}</span></mat-button-toggle>
        <mat-button-toggle value="Downloads">Downloads</mat-button-toggle>
        <mat-button-toggle value="Bookmarks">
          <mat-icon>bookmark</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="Chatbot" matTooltip="Early Adopter Access" *ngIf="genaiChatBotPermission">
          <mat-icon>chat</mat-icon>
          Instant Analyst
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-select [(value)]="activeStoreKey" (selectionChange)="setCollection($event.value)" overlayPanelClass="myPanel">
        <mat-option value="Reports">Reports <span class="badge" *ngIf="tabCounts['Reports'].count > 0">({{ tabCounts['Reports'].count }})</span></mat-option>
        <mat-option value="OperatorProfiles">Operator Profiles <span class="badge" *ngIf="tabCounts['OperatorProfiles'].count > 0">({{ tabCounts['OperatorProfiles'].count }})</span></mat-option>
        <mat-option value="Downloads">Downloads</mat-option>
        <mat-option value="Bookmarks">My Bookmarks</mat-option>
        <mat-option value="Chatbot" matTooltip="Early Adopter Access" *ngIf="genaiChatBotPermission">
          <mat-icon>chat</mat-icon>
          Instant Analyst
        </mat-option>
      </mat-select>
      <div class="filters">
        <button *ngIf="activeStoreKey!=='ReportsNew'" class="button-filters-new" (click)="showFilters($event)" title="{{facetsPrettyText}}">
          <div class="centered-content">
            Filter Results ({{ searchService.filterCount(activeStoreKey) }})
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <circle cx="256" cy="256" r="240.845" fill="#f3f6f7" stroke="#57b947" stroke-width="15" paint-order="normal"/>
              <path d="M370.252 114.939H141.743c-4.602 0-7.254 5.243-4.542 8.961l90.678 123.444c.7.976 1.097 2.134 1.097 3.322v146.395h45.903V250.788c0-1.28.426-2.53 1.219-3.505l98.542-123.2c2.956-3.688.335-9.144-4.389-9.144z" fill="#58b947"/>
            </svg>
          </div>
        </button>
      </div>
    </nav>
  </header>
  <ng-container *ngIf="activeStoreKey==='Chatbot' && genaiChatBotPermission">
    <iv-chatbot [allowedFeature]="genaiChatBotPermission" [store]="searchService.reportsAzss$ | async"></iv-chatbot>
  </ng-container>
  <ng-container *ngIf="showReportsSearchResults">
    <ng-container *ngIf="activeStoreKey!=='Downloads' && activeStoreKey!=='ReportsNew' && activeStoreKey!=='Chatbot'">
      <iv-reports-view *ngIf="showReportsSearchResults" [store]="searchService.reportsAzss$ | async"></iv-reports-view>
    </ng-container>
    <ng-container *ngIf="activeStoreKey==='ReportsNew'">
      <iv-new-research *ngIf="showReportsSearchResults" [store]="reportsNavAzssData"></iv-new-research>
    </ng-container>
    <ng-container *ngIf="activeStoreKey==='Downloads'">
      <iv-downloads-view [store]="searchService.reportsAzss$ | async"></iv-downloads-view>
    </ng-container>
  </ng-container>
</section>
<div *ngIf="showReportFacets">
  <!--  {{searchService.log('*** firing iv-search-filters')}}-->
  <iv-search-filters [pane]="'Reports'" [store]="searchService.reportsAzss$ | async" [state]="researchItem" [defaultFilterId]="sharedService.reportsDefaultFilterId$ | async" class="right"></iv-search-filters>
</div>

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, of, timer, Subject, lastValueFrom, BehaviorSubject, Observable, first} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {environment as ENV} from '../../../environments/environment';
import {AuthService, LogoutOptions} from '@auth0/auth0-angular';
import {DataService} from '../data/data.service';
import {LogService} from '../log/log.service';
import {UserService} from '../user/user.service';
import {Constants} from "../../constants/constants";

@Injectable({
  providedIn: 'root'
})
export class IvauthService {

  redirectUrl: string = '/dashboard';
  authorize: any;
  profile: any;
  permissions: any;
  refreshSubscription: any;

  private ssoAuthComplete = new Subject<boolean>();
  ssoAuthComplete$ = this.ssoAuthComplete.asObservable();

  private userAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userAuthenticated$: Observable<boolean> = this.userAuthenticatedSubject.asObservable();

  constructor(public router: Router, private auth: AuthService, private dataService: DataService, private logService: LogService, private userService: UserService) {
  }

  public async login() {
    await this.auth.loginWithRedirect();
  }

  public setRedirectUrl(url: string) {
    sessionStorage.setItem('redirectUrl', url);
  }

  public async handleAuthentication() {
    const redirectResult = await this.auth.handleRedirectCallback();

    // const authResult: any = await this.auth.getAccessTokenSilently({detailedResponse: true});
    // if (authResult && authResult.access_token && authResult.id_token) {
    //   this.setUser(authResult, 'login_ok', 'login', 'info', null);
    // } else {
    //   await this.router.navigate(['/error'], {queryParams: {error: 'authentication'}});
    // }

    return () => this.auth.getAccessTokenSilently({detailedResponse: true}).pipe(
      first()
    ).subscribe(async (authResult: any) => {
      if (authResult && authResult.access_token && authResult.id_token) {
        this.setUser(authResult, 'login_ok', 'login', 'info', null);
      } else {
        await this.router.navigate(['/error'], {queryParams: {error: 'authentication'}});
      }
    });

  }

  public setSession(authResult: any): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expires_in * 1000) + Date.now());

    sessionStorage.setItem('access_token', authResult.access_token);
    sessionStorage.setItem('id_token', authResult.id_token);
    sessionStorage.setItem('expires_at', expiresAt);
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('permissions');
  }

  public logout(): void {
    // Remove tokens and expiry time from sessionStorage
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('expires_at');
    sessionStorage.removeItem('pendo');
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('permissions');
    sessionStorage.removeItem('redirectUrl');
    sessionStorage.removeItem(Constants.chatbotStorageKey);

    this.unscheduleRenewal();
    // Go back to the home route

    // BUG: this is broken now... plugin must have changed
    this.auth.logout({logoutParams: {returnTo: document.location.origin}});
  }

  public isAuthenticated(): boolean {
    let authenticated: boolean = false;

    let expiresAt: any = sessionStorage.getItem('expires_at');
    try { expiresAt = JSON.parse( expiresAt ) } catch(e) {}

    let profile: any = sessionStorage.getItem('profile');
    try { profile = JSON.parse( profile ) } catch(e) {}

    let permissions = sessionStorage.getItem('permissions');
    if(permissions !== null) {
      try {
        permissions = JSON.parse(permissions)
      } catch (e) {
      }
    }

    authenticated = profile && profile.hasOwnProperty('user_id') &&
      permissions && permissions.hasOwnProperty('permissions') &&
      (new Date().getTime() < expiresAt);

    if(authenticated) {
      this.userAuthenticated$.pipe(
        first()
      ).subscribe((userAuthenticated: boolean) => {
        if(!userAuthenticated) {
          this.userAuthenticatedSubject.next(true);
        }
      });
    }

    return authenticated;
  }

  public async renewToken(firstlogin: boolean) {
    try {

      // BUG: toPromise() is deprecated here... what to replace with?
      //const authResult = await this.auth.getAccessTokenSilently({detailedResponse: true}).toPromise();
      const authResult$ = this.auth.getAccessTokenSilently({detailedResponse: true});
      const authResult = await lastValueFrom(authResult$);
      /*
        {
          "id_token": "eyJh...",
          "access_token": "eyJh...",
          "scope": "openid profile email",
          "expires_in": 86400
        }
      */
      if (firstlogin) {
        this.setUser(authResult, 'login_ok', 'login', 'info', null);
      } else {
        this.setUser(authResult, 'token_renewal_ok', 'silent_login', 'info', null);
      }

    } catch (error) {
      await this.login();
    }
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) return;
    this.unscheduleRenewal();

    const expiresAt = JSON.parse(window.sessionStorage.getItem('expires_at') || '{}');

    //const source =
    of(expiresAt).pipe(mergeMap(expiresAt => {
      const now = Date.now();

      // Use the delay in a timer to
      // run the refresh at the proper time
      return timer(Math.max(1, expiresAt - now));
    }));

  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }

  private redirect(): void {
    if (sessionStorage.getItem('redirectUrl')) {
      this.redirectUrl = sessionStorage.getItem('redirectUrl') || '/';
    }
    // this.router.navigate([this.redirectUrl], {replaceUrl: true, queryParamsHandling: 'preserve'});
    this.router.navigateByUrl(this.redirectUrl, {replaceUrl: true});
  };

  private setUser(authResult: any, event: string, message: string, level: string, options: any) {
    this.setSession(authResult);
    const authorize$ = this.dataService.authorize();

    combineLatest([authorize$])
      .subscribe(([authorize]: any) => {
        if (authorize) {

          const profile = JSON.parse(JSON.stringify(authorize.authorization.profile));
          this.userService.setUserProfile(profile);

          const permissions = JSON.parse(JSON.stringify(authorize.authorization.auth0_permissions));
          this.userService.setUserPermissions(permissions);

          if (permissions && permissions.permissions && permissions.permissions.length === 0) {
            // @ts-ignore
            this.router.navigate(['/error'], {queryParams: {error: 'authorization_permissions'}});
            return false;
          }

          this.userService.getPreferences();

          if (!ENV.review) {
            let sfId = '';
            if (profile && profile.app_metadata && profile.app_metadata.organization && profile.app_metadata.organization.sfId) {
              sfId = profile.app_metadata.organization.sfId;
            }
            const pendoOptions = {
              visitor: {
                id: permissions.user.userId,
                email: permissions.user.email,
                name: `${permissions.user.givenName} ${permissions.user.familyName}`,
                subscription: 'Intelligence'
              },
              account: {
                id: permissions.user.organizationId,
                name: permissions.user.organizationName,
                salesForceId: sfId,
                enverusId: permissions.user.enverusId
              }
            };

            sessionStorage.setItem('pendo', JSON.stringify(pendoOptions));
            // @ts-ignore
            window.pendo.initialize(pendoOptions);
          }

          this.isAuthenticated();  // update authenticated Observable

          this.scheduleRenewal();
          this.redirect();
        }
      }, err => {
        // @ts-ignore
        this.router.navigate(['/error'], {queryParams: {error: 'authorization'}});
        this.ssoAuthComplete.next(false);
      });

  }

}

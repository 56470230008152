import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  standalone: true,
  selector: 'iv-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ErrorPageComponent {
  errorCode: string = '';

  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe(() => {
      //this.getRouteData();
      if (typeof (this.route.snapshot.params['code']) !== 'undefined') {
        this.errorCode = this.route.snapshot.params['code'];
      }
    });
  }
}

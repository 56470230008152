import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AutoExpandDirective} from "./auto-expand.directive";
import {MatIconModule} from "@angular/material/icon";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ReadOnlyDirective} from "../read-only/read-only.directive";
import {LogService} from 'src/app/services/log/log.service';
import {TruncatePipe} from "../../../pipes/truncate/truncate.pipe";
import {Subscription} from "rxjs";
import {ChatbotComponent} from "../chatbot.component";
import {Constants} from "../../../constants/constants";

@Component({
  selector: 'iv-prompt-input',
  templateUrl: './prompt-input.component.html',
  styleUrls: ['./prompt-input.component.scss'],
  imports: [
    AutoExpandDirective,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    ReadOnlyDirective,
  ],
  standalone: true
})
export class PromptInputComponent implements OnInit, OnDestroy {
  @Input() textareaControl = new FormControl<string>('');
  @Input() allowedFeature = false;
  @Output() emitPrompt = new EventEmitter<string | null>();

  textareaSubscription: Subscription = Subscription.EMPTY;

  private truncatePipe: TruncatePipe = new TruncatePipe();

  lockInput: boolean = false;

  constructor(
    private logService: LogService,
    private chatbotComponent: ChatbotComponent
  ) {
  }


  ngOnInit() {
    this.textareaSubscription = this.textareaControl.valueChanges.subscribe(value => {
      if(value) {
        const trimmedValue: string = value.trimStart();
        if(trimmedValue !== value) {
          setTimeout(() => {
            this.textareaControl.setValue(trimmedValue);
          });
        }
        this.chatbotComponent.setUsedTokens(trimmedValue ? trimmedValue.length : 0);
        if (!trimmedValue.length || (trimmedValue.length > Constants.chatbotMaxChars)) {
          this.lockInput = true;
        } else {
          this.lockInput = false;
        }
      } else {
        this.chatbotComponent.setUsedTokens(0);
      }
    });
  }

  logInput(query: string): boolean {
    let queryNoLF: string = query.replace(/[\r\n]+$/, '');
    let queryTrunc: string = this.truncatePipe.transform(queryNoLF, 150);

    this.logService.logPendo('ChatBot Query', {
      query: queryTrunc,
    });

    return true;
  }

  ngOnDestroy() {
    if (this.textareaSubscription !== Subscription.EMPTY) {
      this.textareaSubscription.unsubscribe();
    }
  }

}

import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
// import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from "@angular/material/core";
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgProgressModule } from 'ngx-progressbar';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment as ENV } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { NavbarComponent } from "./features/navbar/navbar.component";
import { SummaryLivefeedComponent } from './pages/summary-livefeed/summary-livefeed.component';

import { ResearchStore } from "./store/research-store/research.store";
import {CgwInterceptor} from './services/cgw/cgw.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // modules
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    // AuthModule.forRoot({
    //   domain: ENV.AUTH_CONFIG.domain,
    //   clientId: ENV.AUTH_CONFIG.clientID,
    //   cacheLocation: ENV.AUTH_CONFIG.cacheLocation,
    //   useRefreshTokens: true,
    //   useRefreshTokensFallback: true,
    //   authorizationParams: {
    //     audience: ENV.AUTH_CONFIG.audience,
    //     redirect_uri: ENV.AUTH_CONFIG.callbackUri
    //   },
    //   httpInterceptor: {
    //     allowedList: [
    //       {
    //         uri: `${ENV.API.vault}/*`,
    //         tokenOptions: {
    //           authorizationParams: {
    //             audience: ENV.AUTH_CONFIG.audience
    //           }
    //         }
    //       },
    //       {
    //         uri: `${ENV.API.prismData}`,
    //         tokenOptions: {
    //           authorizationParams: {
    //             audience: ENV.AUTH_CONFIG.audience
    //           }
    //         }
    //       }
    //     ]
    //   }
    // }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSnackBarModule,
    NgProgressModule,
    // components
    LoginComponent,
    NavbarComponent,
    SummaryLivefeedComponent,
    // dev tools
    StoreDevtoolsModule.instrument({
      name: 'NgRx Demo App',
      logOnly: ENV.production
    }),
  ],
  providers: [ResearchStore,
    provideClientHydration()
    // {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: CgwInterceptor, multi: true},
    // // provideHttpClient(
    // //   withInterceptors([]),
    // //   withInterceptorsFromDi()
    // // ),
  ],
  exports: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }


// stores constants that are required across classes
export class Constants {

  // azure stores defined for each 'pane'
  static readonly ReportsStores: string[] = ['Reports', 'OperatorProfiles', 'Downloads', 'Bookmarks', 'Chatbot', 'ReportsNew'];
  static readonly LivefeedStores: string[] = ['LiveFeedAll', 'News', 'GlobalScouting', 'AnalystTakes', 'LiveFeedNew'];
  static readonly ResearchSpotlightStores: string[] = ['ResearchSpotlight'];

  // command line parameters
  static readonly livefeedSavedFiltersUrl: string = 'lff';                  // load the app with a saved query url in the livefeed pane
  static readonly reportsSavedFiltersUrl: string = 'rf';                    // load the app with a saved query url in the reports pane
  static readonly livefeedPaneQuery: string = 'lfq';                        // load the app with livefeed pane query (search) string and issue search
  static readonly reportsPaneQuery: string = 'rq';                          // load the app with reports pane query (search) string and issue search

  // new articles
  static readonly newArticlesIntervalEnable: boolean = true;                // enable new articles interval updates (disabling also disables snackbar system, research spotlight, new articles counts/badges)
  static readonly newArticlesIntervalSeconds: number = (60);                // look for / calculate new articles count every N seconds
  static readonly newArticlesPublishedSeconds: number = (8*60*60);          // number of seconds (hours*minutes*seconds) since article published to appear on New tab(s)
  static readonly snackbarArticlesPublishedSeconds: number = (10*60);       // number of seconds (hours*minutes*seconds) since article published to appear in New snackbar(s);
                                                                            //   (NOTE: must be less than newArticlesPublishedSeconds as it extracts data from those results!)
  static readonly snackbarRotateSeconds: number = 30;                       // number of seconds (hours*minutes*seconds) to display a given article title before rotation
  static readonly snackbarTitleLength: number = 60;                         // maximum number of characters to show on snackbar for title

  // search
  static readonly suggestResults: boolean = false;                          // whether to enable ajax suggestions while typing into the search box
  static readonly useRestSearchApi: boolean = false;                        // use REST search api instead of javascript sdk (experimental)

  // misc
  static readonly activeFilterKey: string  = 'sf_active_filter';            // custom var on each store to save the active filter to
  static readonly publishedStartDate: string  = '1988-01-01';               // Date Range filter - what to set/reset starting date to
  static readonly facetsChipTextLimit: number = 80;                         // maximum number of characters to show on facets (filters) material chip
  static readonly facetsModifiedIgnoreDates: boolean = true;                // take published date range into account when determining if facets modified

  // messages
  static readonly subscriptionRequiredMessage: string = '** Requires an active Enverus Intelligence subscription, please contact intelligence@enverus.com';

  // chatbot
  static readonly chatbotStorageKey: string = 'chatbot_disclaimer';         // the key of the local storage item to track agreed disclosure
  static readonly chatbotDisclosureDate: string = '2024-02-12';             // date of the disclosure version; this must be updated when the disclosure is changed
                                                                            // in order to re-prompt users to agree (append a letter if multiple in same day, ie: b/c/d...)
  static readonly chatbotMaxChars: number = 1000;                           // the maximum number of characters that can be input in a question

  // research store key for saved filters
  // static readonly SavedFiltersStore: string = 'ActiveFilter';

}

import {ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AzureSearchService } from "../../services/azuresearch/azuresearch.service";
import { SearchService } from "../../services/search/search.service";
import {Subscription} from "rxjs";
import {AzssError} from "../../interface/azss";

@Component({
  selector: 'iv-subscription-filter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './subscription-filter.component.html',
  styleUrls: ['./subscription-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionFilterComponent implements OnDestroy, OnInit {
  @Input() pane: string | undefined;
  @Input() isGlobal: boolean | undefined;
  // @Input() store: any;
  // @Input() facet: any;

  store: any;  // AzssStore;
  storeAzssSubscription: Subscription = Subscription.EMPTY;

  // public azureSearchService = inject( AzureSearchService );

  constructor(
    public searchService: SearchService,
    public azureSearchService: AzureSearchService,
    // private cdr: ChangeDetectorRef
  ) {
    if(this.isGlobal === undefined) {
      this.isGlobal = false;
    }
  }

  ngOnInit(): void {

    if(this.pane === 'Reports') {

      this.storeAzssSubscription = this.searchService.reportsAzss$.subscribe((data: any) => {
        this.store = data;
        // this.azureSearchService.dump_stores();
      });

    } else {

      this.storeAzssSubscription = this.searchService.livefeedAzss$.subscribe((data: any) => {
        this.store = data;
        // this.azureSearchService.dump_stores();
      });

    }

  }

  filterSubscription($event: any, subscription: string) {
    if(!this.isGlobal) {
      this.searchService.ss_set_subscription(this.store.collection, subscription, true);
      this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
      }).catch((error: any) => {
      });
      // this.cdr.detectChanges();
      // if(!this.searchService.ignore_facets_changed_events) {
      //   this.searchService.ss_test_active_filter_changed();
      // }
    }
  }

  getSubscriptionKey(index: number, subscription: any): string {
    return subscription.key;
  }

  ngOnDestroy(): void {
    if(this.storeAzssSubscription !== Subscription.EMPTY) {
      this.storeAzssSubscription.unsubscribe();
    }
  }

}

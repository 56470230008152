import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  constructor(private http: HttpClient) {}

  formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getFormattedDates() {
    const todayDate = new Date();
    const sixMonthsAgoDate = new Date();
    const weekAgoDate = new Date();
    const thirtyDaysAgoDate = new Date();

    weekAgoDate.setDate(weekAgoDate.getDate() - 7);

    thirtyDaysAgoDate.setDate(thirtyDaysAgoDate.getDate() - 30);

    sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 7);

    const today = this.formatDate(todayDate);
    const sixMonthsAgo = this.formatDate(sixMonthsAgoDate);
    const weekAgo = this.formatDate(weekAgoDate);
    const thirtyDaysAgo = this.formatDate(thirtyDaysAgoDate);

    return {
      today,
      sixMonthsAgo,
      weekAgo,
      thirtyDaysAgo,
    };
  }

  getWhereStatements() {
    const { sixMonthsAgo, weekAgo, thirtyDaysAgo, today } =
      this.getFormattedDates();
    const wellQueryCount = `WHERE wells.SpudDate <= '${today}' AND wells.SpudDate>='${thirtyDaysAgo}' AND wells.Country='US'`;
    const wellQueryData = `WHERE wells.SpudDate <= '${weekAgo}' AND wells.SpudDate>='${sixMonthsAgo}' AND wells.Country='US'`;
    const permitsQueryCount = `WHERE enverusPermits.ApprovedDate <= '${today}' AND enverusPermits.ApprovedDate>='${thirtyDaysAgo}' AND enverusPermits.Country='US'`;
    const permitsQueryData = `WHERE enverusPermits.ApprovedDate <= '${weekAgo}' AND enverusPermits.ApprovedDate>='${sixMonthsAgo}' AND enverusPermits.Country='US'`;
    const rigsQueryCount = `WHERE activeRigs.RigId>0 AND activeRigs.Country='US'`;
    const rigsQueryData = `WHERE activeRigs.RigId>0 AND activeRigs.SubmittedDate<= '${weekAgo}' AND activeRigs.SubmittedDate>='${sixMonthsAgo}' AND activeRigs.Country='US'`;
    return {
      wellQueryCount,
      wellQueryData,
      permitsQueryCount,
      permitsQueryData,
      rigsQueryCount,
      rigsQueryData,
    };
  }

  async getWidgetWellData() {
    const { wellQueryCount } = this.getWhereStatements();

    return this.http.post<any>(ENV.API.prismData, {
      query: `SELECT count(DISTINCT wells.WellApi) as count from wells ${wellQueryCount}`,
    });
  }
  async getWidgetPermitsData() {
    const { permitsQueryCount } = this.getWhereStatements();

    return this.http.post<any>(ENV.API.prismData, {
      query: `SELECT count(DISTINCT enverusPermits.WellApi) as count from enverusPermits ${permitsQueryCount}`,
    });
  }
  async getWidgetRigsData() {
    const { rigsQueryCount } = this.getWhereStatements();

    return this.http.post<any>(ENV.API.prismData, {
      query: `Select count(DISTINCT activeRigs.WellApi) as count from activeRigs ${rigsQueryCount}`,
    });
  }
  async getWidgetWellsGraphData() {
    const { wellQueryData } = this.getWhereStatements();

    return this.http.post<any>(ENV.API.prismData, {
      query: `SELECT DATE_TRUNC('week', SpudDate) AS date,COUNT(DISTINCT WellApi) AS count FROM wells ${wellQueryData} GROUP BY date`,
      limit: 100000,
    });
  }
  async getWidgetPermitsGraphData() {
    const { permitsQueryData } = this.getWhereStatements();

    return this.http.post<any>(ENV.API.prismData, {
      query: `SELECT DATE_TRUNC('week', ApprovedDate) AS date,COUNT(DISTINCT WellApi) AS count FROM enverusPermits ${permitsQueryData} GROUP BY date`,
      limit: 100000,
    });
  }
  async getWidgetRigsGraphData() {
    const { rigsQueryData } = this.getWhereStatements();

    return this.http.post<any>(ENV.API.prismData, {
      query: `SELECT DATE_TRUNC('week', SubmittedDate) AS date,COUNT(DISTINCT WellApi) AS count FROM activeRigs ${rigsQueryData} GROUP BY date`,
      limit: 100000,
    });
  }
}

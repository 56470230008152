import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data/data.service';
import {UserService} from '../../services/user/user.service';
import {LogService} from '../../services/log/log.service';
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'iv-contact-analyst-dialog',
  standalone: true,
  templateUrl: './contact-analyst-dialog.component.html',
  styleUrls: ['./contact-analyst-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule
  ]
})
export class ContactAnalystDialogComponent {
  contactAnalystForm = new FormGroup({
    id: new FormControl(this.data.id),
    subject: new FormControl({value: this.data.title, disabled: true}, [Validators.required]),
    key: new FormControl(this.data.key),
    description: new FormControl(null)
  });

  error = false;
  success = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ContactAnalystDialogComponent>, private dataService: DataService, private userService: UserService, private logService: LogService) {
  }

  get f() {
    return this.contactAnalystForm.controls;
  }

  onSubmit() {
    if (this.contactAnalystForm.valid) {
      this.dataService.contactAnalyst(this.contactAnalystForm.getRawValue()).subscribe({
        next: (v) => {
          this.logService.logPendo('Contact Analyst', {
            id: this.data.id,
            key: this.data.key,
            title: this.data.title,
            series: this.data.series,
            type: this.data.projectType,
            collection: this.data.collection,
            primaryAuthor: (this.data.primaryAuthor) ? (this.data.primaryAuthor.displayName) : '',
            authors: this.data.authors,
            publishedDate: this.data.publishedDate,
          });
          this.error = false;
          this.success = true;
        },
        error: (e) => {
          this.success = false;
          this.error = true;
        },
      });
    }
  }

  closeContactAnalystDialog() {
    this.dialogRef.close();
  }
}


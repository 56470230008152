import {Injectable} from '@angular/core';
import {environment as ENV} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';

const AUTHORIZE: string = '/authorize';
const PROFILE: string = '/vault/profile';
const FEEDBACK: string = '/vault/feedback';
const SHARE: string = '/vault/share/email/';
const SHAREFILTER: string = '/vault/share/filter/';
const ASM: string = '/vault/asm/';
const OPTIN: string = '/vault/asm/optin/';
const CONTACT: string = '/vault/contactAnalyst';
const METADATA: string = '/document/metadata/';
const PREVIEW: string = '/document/previewurl/';
const DOCUMENT: string = '/document/downloadurl/';
const ATTACHMENTS: string = '/document/attachmentsurl/';
const INTERACTIVE: string = '/document/interactivereport/';
const SUMMARYPUBLISH: string = '/document/summarypublish/';
const SUMMARYMETADATA: string = '/document/summarymetadata/';
const BULKFILES: string = '/document/bulkfiles/';
const LOADFILTERURLS: string = '/vault/getfilterurls';
const PUBLISHFILTER: string = '/vault/publishFilter';
const ALERTS: string = '/vault/alerts';
const SEARCH: string = '/vault/search';
const TIME = '/time';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) {
  }

  getTime() {
    return this.http.get<any>(ENV.API.vaultPublic + TIME);
  }

  authorize() {
    return this.http.get<any>(ENV.API.vaultProtected + AUTHORIZE);
  }

  async getUserProfileAsync() {
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + PROFILE
    );
    return await lastValueFrom(result$);
  }

  getUserSuppressionList(email: string) {
    return this.http.get<any>(ENV.API.vaultProtected + ASM + `?q=${Date.now()}`);
  }

  updateUserSuppressionList(email: string, suppressions: any) {
    return this.http.post<any>(ENV.API.vaultProtected + ASM, suppressions);
  }

  optInToEmailSeries(seriesid: string) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    const email = profile.email;
    return this.http.post<any>(ENV.API.vaultProtected + OPTIN + email, {series: seriesid});
  }

  getResearchMetaData(id: string | null) {
    return this.http.get<any>(ENV.API.vaultProtected + METADATA + id);
  }

  getDocumentPreview(id: string) {
    return this.http.get<any>(ENV.API.vaultProtected + PREVIEW + id);
  }

  getDocument(id: string) {
    return this.http.get<any>(ENV.API.vaultProtected + DOCUMENT + id);
  }

  getInteractiveReport(id: string | null) {
    return this.http.get<any>(ENV.API.vaultProtected + INTERACTIVE + id);
  }

  getSummaryMetadata(id: string | null) {
    return this.http.get<any>(ENV.API.vaultPublic + SUMMARYMETADATA + id);
    // const result$ = this.http.get<any>(ENV.API.vaultPublic + SUMMARYMETADATA + id);
    // return await lastValueFrom(result$);
  }

  getAttachments(id: string) {
    return this.http.get<any>(ENV.API.vaultProtected + ATTACHMENTS + id);
  }

  getMediaPlaylist(playListSrc: any) {
    return this.http.get<any>(playListSrc);
  }

  getWells() {
    return this.http.get<any>(`/motm/markers.php`);
  }

  getTickers() {
    return this.http.get<any>(`/motm/quotes.php`);
  }

  getNews() {
    return this.http.get<any>(`/motm/news.php`);
  }

  contactAnalyst(metadata: any) {
    return this.http.post<any>(ENV.API.vaultProtected + CONTACT, metadata);
  }

  submitFeedback(feedback: any) {
    return this.http.post(ENV.API.vaultProtected + FEEDBACK, feedback);
  }

  share(id: any, shareData: any) {
    return this.http.post(ENV.API.vaultProtected + SHARE + `${id}`, shareData);
  }

  shareFilter(shareData: any) {
    return this.http.post(ENV.API.vaultProtected + SHAREFILTER, shareData);
  }

  async getFilters() {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getFilters',
      {params: params}
    );
    return await lastValueFrom(result$);
  }

  async getFiltersGlobal() {
    // const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    // let params = new HttpParams()
    //   .set('userId', profile.user_id);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getFiltersGlobal',
      // {params: params}
    );
    return await lastValueFrom(result$);
  }

  async loadFilter(id: number) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id)
      .set('id', id);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getFilter',
      {params: params}
    );
    return await lastValueFrom(result$);
  }

  async deleteFilter(id: number) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id)
      .set('id', id);
    const result$ = this.http.delete<any>(
      ENV.API.vaultProtected + '/vault/deleteFilter',
      {params: params}
    );
    return await lastValueFrom(result$);
  }

  async loadFilterUrl(url: string) {

    // const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('url', url);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getFilterUrl',
      {params: params}
    );
    return await lastValueFrom(result$);
  }

  async loadFilterUrls(urlkeys: any) {
    const body = {
      urlkeys: urlkeys
    };
    const result$ = this.http.post<any>(ENV.API.vaultProtected + LOADFILTERURLS, body);
    return await lastValueFrom(result$);
  }

  async saveFilterUrl(url: string, filter_id: number, filters: any) {

    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let body = {
      url: url,
      userId: profile.user_id,
      filter_id: filter_id,
      filterJson: JSON.stringify(filters)
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + '/vault/saveFilterUrl',
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async getBulkFiles(listJson: string) {
    const body = {
      downloadQueue: listJson
    };
    const result$ = this.http.post<any>(ENV.API.vaultProtected + BULKFILES, body);
    return await lastValueFrom(result$);
  }

  async saveFilter(id: number, title: string, filters: any) {

    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const body = {
      id: id,
      userId: profile.user_id,
      title: title,
      filterJson: JSON.stringify(filters)
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + '/vault/saveFilter',
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async publishFilter(id: number, isGlobal: boolean) {

    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const body = {
      id: id,
      userId: profile.user_id,
      isGlobal: isGlobal
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + PUBLISHFILTER,
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async updateFilterSettings(id: number, settings: any) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let body = {
      id: id,
      userId: profile.user_id,
      settings: JSON.stringify(settings)
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + '/vault/updateFilterSettings',
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async getFavorites() {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getFavorites',
      {params: params}
    );
    return await lastValueFrom(result$);  // this was non-await!
  }

  async loadFavorite(id: number) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id)
      .set('id', id);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getFavorite',
      {params: params}
    );
    return await lastValueFrom(result$);  // this was non-await!
  }

  async deleteFavorite(id: number) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id)
      .set('id', id);
    const result$ = this.http.delete<any>(
      ENV.API.vaultProtected + '/vault/deleteFavorite',
      {params: params}
    );
    return await lastValueFrom(result$);
  }

  async saveFavorite(id: number, title: string, favorites: any) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let body = {
      id: id,
      userId: profile.user_id,
      title: title,
      favoriteJson: JSON.stringify(favorites)
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + '/vault/saveFavorite',
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  getRecents() {

    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id);
    return this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getRecents',
      {params: params}
    );
  }

  async loadRecent(id: number) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id)
      .set('id', id);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + '/vault/getRecent',
      {params: params}
    );
    return await lastValueFrom(result$);  // this was non-await!
  }

  async deleteRecent(id: number) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let params = new HttpParams()
      .set('userId', profile.user_id)
      .set('id', id);
    const result$ = this.http.delete<any>(
      ENV.API.vaultProtected + '/vault/deleteRecent',
      {params: params}
    );
    return await lastValueFrom(result$);
  }

  async saveRecent(id: number, title: string, recents: any) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let body = {
      id: id,
      userId: profile.user_id,
      title: title,
      recentJson: JSON.stringify(recents)
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + '/vault/saveRecent',
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async publishSummary(issue: string) {
    const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
    // let params = new HttpParams()
    //   .set('issue', issue);
    const result$ = this.http.get<any>(
      ENV.API.vaultProtected + SUMMARYPUBLISH + issue
      // {params: params}
    );
    return await lastValueFrom(result$);  // this was non-await!
  }

  async getAlerts() {
    const result$ = this.http.get<any>(ENV.API.vaultProtected + ALERTS);
    return await lastValueFrom(result$);
  }

  async postAlert(payload: object) {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let body = {
      payload: payload
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + ALERTS,
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async updateAlert(id: string, payload: object) {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let body = {
      payload: payload
    };
    const result$ = this.http.put<any>(
      ENV.API.vaultProtected + ALERTS + '/' + id,
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

  async deleteAlert(id: string) {
    const result$ = this.http.delete<any>(
      ENV.API.vaultProtected + ALERTS + '/' + id
    );
    return await lastValueFrom(result$);
  }

  async azureSearchREST(payload: object) {
    let headers = new HttpHeaders()
      .set('api-key', ENV.SEARCH.queryKey)
      .set('Content-Type', 'application/json');
    let body = {
      payload: payload
    };
    const result$ = this.http.post<any>(
      ENV.API.vaultProtected + SEARCH,
      JSON.stringify(body),
      {headers: headers}
    );
    return await lastValueFrom(result$);
  }

}

import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from "rxjs";

import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";

import { IvauthService } from "../../services/ivauth/ivauth.service";
import { UserService } from "../../services/user/user.service";
import {RouterModule} from '@angular/router';
import {LogService} from '../../services/log/log.service';
import {FeedbackComponent} from "../../dialogs/feedback/feedback/feedback.component";

@Component({
  selector: 'iv-navbar',
  standalone: true,
  imports: [CommonModule, MatMenuModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnDestroy {
  menuopen: boolean = false;
  profileSubscription: Subscription;
  profile: any;

  authenticatedSub: Subscription = Subscription.EMPTY;
  authenticated: boolean = false;

  constructor(public ivauth: IvauthService, private userService: UserService, private dialog: MatDialog, private logService:LogService) {
    this.profile = this.userService.getUserProfile();
    this.profileSubscription = this.userService.userProfile.subscribe(profile => {
      this.profile = profile;
    });

    this.authenticatedSub = this.ivauth.userAuthenticated$.subscribe( (authenticated: boolean) => {
      this.authenticated = authenticated;
    });

  }

  logPendoEvent(event:string){
    this.logService.logPendo(`Enverus Logo - ${event}`, {});
  }

  toggleMobileMenu() {
    this.menuopen = !this.menuopen;
  }

  showSurvey(){
    window.open(`https://rsenergy.ca1.qualtrics.com/jfe/form/SV_b1NvqfwkZbpKINT?user=${this.profile.email}&fullname=${this.profile.firstname || this.profile.given_name}%20${this.profile.lastname}&subscription=RS%20Research&company=${this.profile.app_metadata.organization.name}`);
    return false;
  }

  showFeedback(actions?: any) {
    //window['rsResearchFeedback']();

    let dialogRef = this.dialog.open(FeedbackComponent, {
      panelClass: 'iv-feedback',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: actions
    });
  }

  showEmailOptin(actions?: any) {
    //window['rsResearchFeedback']();

    // let dialogRef = this.dialog.open(EmailOptinComponent, {
    //   panelClass: 'rsv-email-optin-dialog',
    //   disableClose: false,
    //   autoFocus: true,
    //   width: '600px',
    //   height: 'auto',
    //   data: actions
    // });
  }

  ngOnDestroy() {
    this.profileSubscription.unsubscribe();
  }

}

<ul>
  <ng-container *ngIf="azureSearchService.get_subscription_filters_filters(store.collection) as subscriptions">
    <ng-container *ngIf="subscriptions">
      <li *ngFor="let subscription of subscriptions; trackBy: getSubscriptionKey"
      [ngClass]="subscription.active ? 'active' : ''" (click)="filterSubscription($event, subscription.key)">
        {{subscription.name}}
      </li>
    </ng-container>
  </ng-container>
</ul>

import { Component } from '@angular/core';

@Component({
  selector: 'iv-four-o-four-page',
  templateUrl: './four-o-four-page.component.html',
  styleUrls: ['./four-o-four-page.component.scss']
})
export class FourOFourPageComponent {

}

import { Injectable } from '@angular/core';
import {UserService} from "../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private userService:UserService) {
    const preferences = this.userService.getPreferences();
    this.setTheme(preferences.theme)
  }

  public setTheme(theme: string) {
    // const body: any = document.querySelector('body');
    // if(body.className.includes('prism')){
    //   body.className = 'prism ' + theme;
    // }else{
    //   body.className = theme;
    // }
  }

  public getTheme(){
    return this.userService.getPreferences().theme;
  }

  public setLoggedIn(){
    // @ts-ignore
    document.querySelector('html').classList.add('logged-in');
  }
}
